import * as React from "react";

const Smoking = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10 0c0 .548-.043 1.65.418 2.787.461 1.136 1.529 2.28 3.328 2.752 1.153.303 2.35.594 3.145 1.002C17.685 6.949 18 7.256 18 8h2c0-1.542-1.055-2.652-2.195-3.238-1.14-.586-2.443-.867-3.551-1.158-1.282-.337-1.714-.907-1.982-1.569C12.002 1.374 12 .595 12 0h-2Zm5 0v2c.9 0 1.988.153 2.756.508.768.354 1.18.754 1.248 1.576A1 1 0 0 0 20 5c.667 0 1.06.268 1.418.805C21.776 6.34 22 7.167 22 8h2c0-1.167-.276-2.341-.918-3.305-.517-.775-1.384-1.29-2.379-1.506-.369-1.147-1.142-2.051-2.11-2.498C17.414.146 16.1 0 15 0ZM0 9v6h24V9H0Zm2 2h16v2H2v-2Zm18 0h2v2h-2v-2Z"
      fill="currentColor"
    />
  </svg>
);

export default Smoking;
