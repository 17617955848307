import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import * as Styled from "./StylesCModal";

const SelectInput = ({ type, flowValue }) => {
  const [visible, isVisible] = useState(false);

  const handleOk = () => {
    flowValue("Delete_Confirmed");
  };
  const handleCancel = () => {
    flowValue("Delete_Canceled");
  };

  useEffect(() => {
    const handleVisibility = () => {
      switch (type) {
        case "Delete":
          isVisible(true);
          break;
        default:
          isVisible(false);
          break;
      }
    };
    handleVisibility();
  }, [type]);


  return (
    <Styled.SelectInputWrapper visible={visible}>
      <Styled.SelectInputHeaderWrapper>
        <AnimatePresence mode={"popLayout"}>
          {type === "Delete" ? (
            <>
              <Styled.SelectInputLabel
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  x: -20
                }}
                animate={{
                  opacity: 1,
                  x: 0
                }}
                exit={{
                  opacity: 0,
                  x: 20
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"1"}
              >
                ¿Estas seguro que deseas borrarla?
              </Styled.SelectInputLabel>
              <Styled.SelectInputDescription
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  y: -10
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                exit={{
                  opacity: 0,
                  y: 10
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"1.1"}
              >
                Esta operación no es reversible
              </Styled.SelectInputDescription>
            </>
          ) : null}
        </AnimatePresence>
      </Styled.SelectInputHeaderWrapper>
      <Styled.CTAWrapper>
        <Styled.CTAButton as={motion.div} whileTap={{ scale: 0.9 }} onClick={handleCancel} >
          <p>cancelar</p>
        </Styled.CTAButton>
        <Styled.CTAButton
          color={"featured"}
          as={motion.div}
          whileTap={{ scale: 0.9 }}
          onClick={handleOk}
        >
          <p>Si</p>
        </Styled.CTAButton>
      </Styled.CTAWrapper>
    </Styled.SelectInputWrapper>
  );
};

export default SelectInput;
