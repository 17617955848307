import * as React from "react";

const Landscape = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#a)">
      <path
        d="M17 3c-1.645 0-3 1.355-3 3s1.355 3 3 3 3-1.355 3-3-1.355-3-3-3Zm0 2c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1ZM8.506 8.986-.041 20h24.176l-1.367-1.64-5.737-6.885-3.511 3.88-5.014-6.369Zm.01 3.248L13.055 18H4.04l4.475-5.766Zm8.453 2.291L19.865 18h-6.04l3.144-3.475Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#EBF2FA" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Landscape;
