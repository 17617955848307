import { useEffect, useState } from "react";
import { supabase } from '../supabaseClient'

import './Avatar.scss'
import { Edit } from '../assets/icons/Index'
import { Placeholder } from "../assets/images/Index";

export default function Avatar({ url, onUpload, session }) {
    
    const [loading, isLoading] = useState(true);
    const [avatarUrl, setAvatarUrl] = useState(null);
    const[uploading, setUpLoading] = useState(null);

    useEffect(() => {
        if (url) downloadImage(url)
    }, [url])

    const downloadImage = async (path) => {
        isLoading(true);
        try {
            const { data, error } = await supabase.storage.from('avatars').download(path)
            if (error) {
                setAvatarUrl(url)
            }

            if (data) {
                const url = URL.createObjectURL(data)
                setAvatarUrl(url)
            }
            
        } catch (error) {
            alert(error.message);
        }
        finally {
            isLoading(false);
        }
    }

    const uploadAvatar = async (event) => {
        isLoading(true);
        try {
            setUpLoading(true);

            if (!event.target.files || event.target.files.length === 0) {
                throw new Error('You must select an image to upload.')
            }

            const file = event.target.files[0]
            const fileExt = file.name.split('.').pop()
            const fileName = `${Math.random()}.${fileExt}`
            const filePath = `${fileName}`

            let { error: uploadError } = await supabase.storage.from('avatars').upload(filePath, file)

            try {
                const { user } = session

                const updates = {
                    id: user.id,
                    avatar_url: filePath,
                    updated_at: new Date(),
                }

                let { error } = await supabase.from('profiles').upsert(updates)

                if (error) {
                    throw error
                }
            }
            catch (error) {
                alert(error.message)
            }

            if (uploadError) {
                throw uploadError
            }

            onUpload()
        } catch(error) {
            alert(error.mesasge)
        } finally {
            setUpLoading(false)
            isLoading(false);
        }
    }

    return (
        <>
        {
            loading ?
            (
                <svg className='loader__container' viewBox="25 25 50 50">
                    <circle className='loader__stroke' r="20" cy="50" cx="50"></circle>
                </svg>
            )
            :
            (
                <div className="avatar">
                    <img 
                        src={avatarUrl ? avatarUrl : Placeholder } 
                        alt={avatarUrl ? 'Avatar' : 'No Image'}
                    />
                    {uploading ? (
                        <svg className='loader__container' viewBox="25 25 50 50">
                            <circle className='loader__stroke' r="20" cy="50" cx="50"></circle>
                        </svg>
                    ) : (
                        <>
                            <label className="avatar__submit" htmlFor="single">
                                <Edit />
                            </label>
                            <div className="upload__input">
                                <input 
                                    type="file"
                                    id="single"
                                    accept="image/"
                                    onChange={uploadAvatar}
                                    disabled={uploading} 
                                />
                            </div>
                        </>
                    )}
                </div>
            )
        }
        </>
    )
}