import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import * as Styled from "./SummaryStyles";
import MaskedInput from "react-text-mask";
import emailMask from "text-mask-addons/dist/emailMask";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/plugin/isBetween";
import "dayjs/plugin/isSameOrAfter";
import { Edit, Save } from '../../../../assets/icons/Index'
import DatePicker from '../datepicker/DatePicker'

const Summary = ({ flowCall, flowValue, summaryData, submitData, updateData, deleteData }) => {
  // Time Context && Evaluators
  dayjs.locale("es");
  const isBetween = require("dayjs/plugin/isBetween");
  const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrAfter);

  // Entry Data Controllers
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [guests, setGuests] = useState();
  const [fromDate, setFromDate] = useState();
  const [duration, setDuration] = useState();
  const [entryTime, setEntryTime] = useState();
  const [toDate, setToDate] = useState();
  const [exitTime, setExtTime] = useState();
  const [type, setType] = useState();

  // Entry Data Storage

  const entryData = [
    {
      name: name,
      email: email,
      phone: phone,
      guests: guests,
      fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
      duration: duration,
      entryTime: entryTime,
      toDate: dayjs(toDate).format("YYYY-MM-DD"),
      exitTime: exitTime
    }
  ];

  // Rendering Control
  const [visible, isVisible] = useState(false);

  // Picker Controllers
  const [rdurationpicker, setRDurationPicker] = useState(false);
  const [entrytimepicker, setEntryTimePicker] = useState(false);
  const [exittimepicker, setExitTimePicker] = useState(false);
  const [fromdatepicker, setFromDatePicker] = useState(false);
  const [todatepicker, setToDatePicker] = useState(false);
  const [autoslot, setAutoSlot] = useState();

  // Input Field Edit Controllers
  const [editname, setEditName] = useState(false);
  const [editemail, setEditEmail] = useState(false);
  const [editphone, setEditPhone] = useState(false);
  const [editguests, setEditGuests] = useState(false);

  // Pickers Menu Options
  const reservationDuration = [
    {
      id: "1",
      type: "rduration",
      name: "12 Horas",
      value: "12"
    },
    {
      id: "2",
      type: "rduration",
      name: "24 Horas",
      value: "24"
    },
    {
      id: "3",
      type: "rduration",
      name: "Más de 24 Horas",
      value: "24+"
    }
  ];
  const entryTimeOptions = [
    {
      id: "1",
      type: "entryTime",
      name: "Día",
      value: "day"
    },
    {
      id: "2",
      type: "entryTime",
      name: "Noche",
      value: "night"
    }
  ];
  const exitTimeOptions = [
    {
      id: "1",
      type: "exitTime",
      name: "Día",
      value: "twilight"
    },
    {
      id: "2",
      type: "exitTime",
      name: "Noche",
      value: "afternoon"
    }
  ];

  // Event Handlers
  const handleSelectInputClick = (m) => {
    if (m.type === "rduration") {
      if (m.value === '12') {
        if (entryTime === 'day') {
          setDuration(m.value);
          setToDate(dayjs(fromDate).format('YYYY-MM-DD'));
          setExtTime('afternoon');
          setAutoSlot(true);
        } else if (entryTime === 'night') {
          setDuration(m.value);
          setToDate(dayjs(fromDate).add(1, 'day').format('YYYY-MM-DD'))
          setExtTime('twilight')
          setAutoSlot(true);
        }
      } else if (m.value === '24') {
        if (entryTime === 'day') {
          setDuration(m.value);
          setToDate(dayjs(fromDate).add(1, 'day').format('YYYY-MM-DD'));
          setExtTime('twilight');
          setAutoSlot(true);
        } else if (entryTime === 'night') {
          setDuration(m.value);
          setToDate(dayjs(fromDate).add(1, 'day').format('YYYY-MM-DD'))
          setExtTime('afternoon')
          setAutoSlot(true);
        }
      } else if (m.value === '24+') {
        setDuration(m.value);
        setAutoSlot(false);
      }
    } else if (m.type === "entryTime") {
      if (duration === '12') {
        if (m.value === 'day') {
          setEntryTime(m.value);
          setToDate(dayjs(fromDate).format('YYYY-MM-DD'));
          setExtTime('afternoon');
        } else if (m.value === 'night') {
          setEntryTime(m.value);
          setToDate(dayjs(fromDate).add(1, 'day').format('YYYY-MM-DD'))
          setExtTime('twilight')
        }
      } else if (duration === '24') {
        if (m.value === 'day') {
          setEntryTime(m.value);
          setExtTime('twilight');
        } else if (m.value === 'night') {
          setEntryTime(m.value);
          setExtTime('afternoon');
        }
      } else {
        setEntryTime(m.value);
      }
    } else if (m.type === "exitTime") {
      setExtTime(m.value);
    }
  };
  const handleNext = () => {
    const handleFlowValue = () => {
      switch (flowCall) {
        case "summary":
          submitData(entryData);
          flowValue("submit");
          break;
        default:
          break;
      }
    };
    handleFlowValue();
  };
  const handleDelete = () => {
    deleteData('Delete');
    flowValue("Delete");
  };
  const handleUpdate = () => {
    updateData(entryData);
  };
  const handleFromDateEditClick = () => {
    setFromDatePicker(!fromdatepicker);
    if (fromdatepicker === false) {
      setType("fromDSummary");
    }
  };
  const handleToDateEditClick = () => {
    setToDatePicker(!todatepicker);
    if (todatepicker === false) {
      setType("toDSummary");
    }
  };
  const handleCancel = () => {
    flowValue('closeModal')
  };

  const handleFlow = (string) => {
    switch (string) {
      case "closeFD":
        setType("closeFD");
        setFromDatePicker(!fromdatepicker);
        break;
      case "closeTD":
        setType("closeTD");
        setToDatePicker(!todatepicker);
        break;
      default:
        break;
    }
  };

  const handleFromDateChange = (selectedDate) => {
    setFromDate(dayjs(selectedDate).format("YYYY-MM-DD"));
    if (duration === '12') {
      if (entryTime === 'day') {
        setToDate(dayjs(selectedDate).format('YYYY-MM-DD'))
      } else if (entryTime === 'night') {
        setToDate(dayjs(selectedDate).add(1, 'day').format('YYYY-MM-DD'))
      }
    } else if (duration === '24') {
      if (entryTime === 'day') {
        setToDate(dayjs(selectedDate).add(1, 'day').format('YYYY-MM-DD'))
      } else if (entryTime === 'night') {
        setToDate(dayjs(selectedDate).add(1, 'day').format('YYYY-MM-DD'))
      }
    }
  };
  const handleToDateChange = (selectedDate) => {
    setToDate(dayjs(selectedDate).format("YYYY-MM-DD"));
  };

  // Picker Auto Closer
  useEffect(() => {
    const closeDDown = (e) => {
      if (e.target.id !== "tdown") {
        if (e.target.parentElement.id !== "tdown") {
          setRDurationPicker(false);
        }
      }
      if (e.target.id !== "entryTime") {
        if (e.target.parentElement.id !== "entryTime") {
          setEntryTimePicker(false);
        }
      }
      if (e.target.id !== "exitTime") {
        if (e.target.parentElement.id !== "exitTime") {
          setExitTimePicker(false);
        }
      }
    };
    document.body.addEventListener("click", closeDDown);
    return () => document.body.removeEventListener("click", closeDDown);
  }, []);

  useEffect(() => {
    const handleVisibility = () => {
      switch (flowCall) {
        case "summary":
          isVisible(true);
          break;
        case "submit":
          isVisible(true);
          break;
        case "own":
          isVisible(true);
          break;
        default:
          isVisible(false);
          break;
      }
    };
    handleVisibility();
  }, [flowCall]);

  useEffect(() => {
    setName(summaryData[0].name);
    setEmail(summaryData[0].email);
    setPhone(summaryData[0].phone);
    setGuests(summaryData[0].guests);
    setFromDate(summaryData[0].fromDate);
    setDuration(summaryData[0].duration);
    setEntryTime(summaryData[0].entryTime);
    setToDate(summaryData[0].toDate);
    setExtTime(summaryData[0].exitTime);
    setAutoSlot(summaryData[0].autoslot)
  }, [flowCall]);

  return (
    <Styled.SelectInputWrapper visible={visible}>
      <AnimatePresence>
        {fromdatepicker ? (
          <motion.div
            initial={{
              opacity: 0.5,
              scale: 0.6
            }}
            animate={{
              opacity: 1,
              scale: 1
            }}
            exit={{
              opacity: 0,
              scale: 0.4
            }}
            transition={{
              type: "spring",
              duration: 0.4
            }}
            // key={"1"}
          >
            <DatePicker
              type={type}
              flowValue={handleFlow}
              changeFromDate={handleFromDateChange}
            />
          </motion.div>
        ) : todatepicker ? (
          <motion.div
            initial={{
              opacity: 0.5,
              scale: 0.6
            }}
            animate={{
              opacity: 1,
              scale: 1
            }}
            exit={{
              opacity: 0,
              scale: 0.4
            }}
            transition={{
              type: "spring",
              duration: 0.4
            }}
            // key={"2"}
          >
            <DatePicker
              type={type}
              flowValue={handleFlow}
              changeToDate={handleToDateChange}
            />
          </motion.div>
        ) : (
          <>
            <Styled.SelectInputHeaderWrapper
              as={motion.div}
              initial={{
                opacity: 0.5,
                scale: 0.6
              }}
              animate={{
                opacity: 1,
                scale: 1
              }}
              exit={{
                opacity: 0,
                scale: 0.4
              }}
              transition={{
                type: "spring",
                duration: 0.4
              }}
            >
              <Styled.SHeaderLabel>
                <p>tus datos</p>
              </Styled.SHeaderLabel>
              <Styled.SummaryTitle>Resumen de reservación</Styled.SummaryTitle>
            </Styled.SelectInputHeaderWrapper>
            <div className="scroll__container">
              <Styled.RowDataWrapper>
                <Styled.DataFieldTitle>nombre</Styled.DataFieldTitle>
                <Styled.SelectInputField style={{ padding: "0" }}>
                  <MaskedInput
                    className="masked__input nooutline"
                    placeholder="Ingresar Nombre"
                    mask={(s) => Array.from(s).map(() => /[A-Za-z\s]/i)}
                    id="name-input"
                    guide={false}
                    disabled={editname === false ? true : false}
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                  />
                  <Styled.SelectInputFieldDDown
                    onClick={() => setEditName(!editname)}
                    as={motion.div}
                    whileTap={{ scale: 0.8 }}
                    style={{ position: "absolute", right: "8px" }}
                  >
                    <Edit
                      style={{ display: editname === true ? "none" : "flex" }}
                    />
                    <Save
                      style={{ display: editname === true ? "flex" : "none" }}
                    />
                  </Styled.SelectInputFieldDDown>
                </Styled.SelectInputField>
              </Styled.RowDataWrapper>
              <Styled.RowDataWrapper>
                <Styled.DataFieldTitle>correo</Styled.DataFieldTitle>
                <Styled.SelectInputField style={{ padding: "0" }}>
                  <MaskedInput
                    style={{
                      textTransform: "unset"
                    }}
                    className="masked__input nooutline"
                    placeholder="Ingresar Correo"
                    mask={emailMask}
                    id="email-input"
                    guide={true}
                    disabled={editemail === false ? true : false}
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                  />
                  <Styled.SelectInputFieldDDown
                    onClick={() => setEditEmail(!editemail)}
                    id="payment"
                    as={motion.div}
                    whileTap={{ scale: 0.8 }}
                    style={{ position: "absolute", right: "8px" }}
                  >
                    <Edit
                      style={{ display: editemail === true ? "none" : "flex" }}
                    />
                    <Save
                      style={{ display: editemail === true ? "flex" : "none" }}
                    />
                  </Styled.SelectInputFieldDDown>
                </Styled.SelectInputField>
              </Styled.RowDataWrapper>
              <Styled.RowDataWrapper>
                <Styled.DataFieldTitle>teléfono</Styled.DataFieldTitle>
                <Styled.SelectInputField style={{ padding: "0" }}>
                  <MaskedInput
                    className="masked__input nooutline"
                    placeholder="Ingresar Teléfono"
                    mask={[
                      "+",
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      " ",
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/
                    ]}
                    id="phone-input"
                    guide={true}
                    type="tel"
                    disabled={editphone === false ? true : false}
                    value={phone}
                    onChange={(e) => setPhone(e.currentTarget.value)}
                  />
                  <Styled.SelectInputFieldDDown
                    onClick={() => setEditPhone(!editphone)}
                    as={motion.div}
                    whileTap={{ scale: 0.8 }}
                    style={{ position: "absolute", right: "8px" }}
                  >
                    <Edit style={{ display: editphone ? "none" : "flex" }} />
                    <Save style={{ display: editphone ? "flex" : "none" }} />
                  </Styled.SelectInputFieldDDown>
                </Styled.SelectInputField>
              </Styled.RowDataWrapper>
              <Styled.RowDataWrapper>
                <Styled.DataFieldTitle>huéspedes</Styled.DataFieldTitle>
                <Styled.SelectInputField style={{ padding: "0" }}>
                  <MaskedInput
                    className="masked__input nooutline"
                    placeholder="2"
                    mask={[/[1-9]/, /\d/, /\d/]}
                    id="phone-input"
                    guide={false}
                    type="tel"
                    disabled={editguests === false ? true : false}
                    value={guests}
                    onChange={(e) => setGuests(e.currentTarget.value)}
                  />
                  <Styled.SelectInputFieldDDown
                    onClick={() => setEditGuests(!editguests)}
                    as={motion.div}
                    whileTap={{ scale: 0.8 }}
                    style={{ position: "absolute", right: "8px" }}
                  >
                    <Edit style={{ display: editguests ? "none" : "flex" }} />
                    <Save style={{ display: editguests ? "flex" : "none" }} />
                  </Styled.SelectInputFieldDDown>
                </Styled.SelectInputField>
              </Styled.RowDataWrapper>
              <Styled.RowDataWrapper>
                <Styled.DataFieldTitle>fecha de entrada</Styled.DataFieldTitle>
                <Styled.SelectInputField>
                  <Styled.SelectInputFieldValue>
                    {dayjs(fromDate).format("DD - MMMM")}
                  </Styled.SelectInputFieldValue>
                  <Styled.SelectInputFieldDDown
                    fromdatepicker={fromdatepicker}
                    onClick={handleFromDateEditClick}
                    as={motion.div}
                    whileTap={{ scale: 0.8 }}
                  >
                    <Edit />
                  </Styled.SelectInputFieldDDown>
                </Styled.SelectInputField>
              </Styled.RowDataWrapper>
              <Styled.RowDataWrapper>
                <Styled.DataFieldTitle>tiempo de entrada</Styled.DataFieldTitle>
                <Styled.SelectInputField>
                  <Styled.SelectInputFieldValue>
                    {
                      entryTime === 'day' ?
                      'Día'
                      : 'Noche'
                    }
                  </Styled.SelectInputFieldValue>
                  <Styled.SelectInputFieldDDown
                    entrytimepicker={entrytimepicker}
                    onClick={() => setEntryTimePicker(!entrytimepicker)}
                    id="entryTime"
                    as={motion.div}
                    whileTap={{ scale: 0.8 }}
                  >
                    <Edit id="entryTime" />
                  </Styled.SelectInputFieldDDown>
                  {entrytimepicker ? (
                    <AnimatePresence>
                      <Styled.SelectInputFieldOptionsWrapper
                        as={motion.div}
                        initial={{
                          opacity: 0.5,
                          scale: 0.8
                        }}
                        animate={{
                          opacity: 1,
                          scale: 1
                        }}
                        exit={{
                          opacity: 0,
                          scale: 0.8
                        }}
                        transition={{
                          type: "spring",
                          duration: 0.3
                        }}
                      >
                        {entryTimeOptions.map((m) => (
                          <Styled.SelectInputFieldOption
                            onClick={() => handleSelectInputClick(m)}
                            key={m.id}
                          >
                            <p>{m.name}</p>
                          </Styled.SelectInputFieldOption>
                        ))}
                      </Styled.SelectInputFieldOptionsWrapper>
                    </AnimatePresence>
                  ) : null}
                </Styled.SelectInputField>
              </Styled.RowDataWrapper>
              <Styled.RowDataWrapper>
                <Styled.DataFieldTitle>tiempo de estadía</Styled.DataFieldTitle>
                <Styled.SelectInputField>
                  <Styled.SelectInputFieldValue>
                    {`${duration} Horas`}
                  </Styled.SelectInputFieldValue>
                  <Styled.SelectInputFieldDDown
                    rdurationpicker={rdurationpicker}
                    onClick={() => setRDurationPicker(!rdurationpicker)}
                    id="tdown"
                    as={motion.div}
                    whileTap={{ scale: 0.8 }}
                  >
                    <Edit id="tdown" />
                  </Styled.SelectInputFieldDDown>
                  {rdurationpicker ? (
                    <AnimatePresence>
                      <Styled.SelectInputFieldOptionsWrapper
                        as={motion.div}
                        initial={{
                          opacity: 0.5,
                          scale: 0.8
                        }}
                        animate={{
                          opacity: 1,
                          scale: 1
                        }}
                        exit={{
                          opacity: 0,
                          scale: 0.8
                        }}
                        transition={{
                          type: "spring",
                          duration: 0.3
                        }}
                      >
                        {reservationDuration.map((m) => (
                          <Styled.SelectInputFieldOption
                            onClick={() => handleSelectInputClick(m)}
                            key={m.id}
                          >
                            <p>{m.name}</p>
                          </Styled.SelectInputFieldOption>
                        ))}
                      </Styled.SelectInputFieldOptionsWrapper>
                    </AnimatePresence>
                  ) : null}
                </Styled.SelectInputField>
              </Styled.RowDataWrapper>
              <Styled.RowDataWrapper>
                <Styled.DataFieldTitle>fecha de salida</Styled.DataFieldTitle>
                <Styled.SelectInputField>
                  <Styled.SelectInputFieldValue>
                    {dayjs(toDate).format("DD - MMMM")}
                  </Styled.SelectInputFieldValue>
                  <Styled.SelectInputFieldDDown
                    todatepicker={todatepicker}
                    onClick={handleToDateEditClick}
                    as={motion.div}
                    whileTap={{ scale: 0.8 }}
                    style={{
                      display: autoslot ?
                      'none'
                      : 'flex'
                    }}
                  >
                    <Edit />
                  </Styled.SelectInputFieldDDown>
                </Styled.SelectInputField>
              </Styled.RowDataWrapper>
              <Styled.RowDataWrapper>
                <Styled.DataFieldTitle>tiempo de salida</Styled.DataFieldTitle>
                <Styled.SelectInputField>
                  <Styled.SelectInputFieldValue>
                    {
                      exitTime === 'twilight' ? 'Día' : 'Noche'
                    }
                  </Styled.SelectInputFieldValue>
                  <Styled.SelectInputFieldDDown
                    exittimepicker={exittimepicker}
                    onClick={() => setExitTimePicker(!exittimepicker)}
                    id="exitTime"
                    as={motion.div}
                    whileTap={{ scale: 0.8 }}
                    style={{
                      display: autoslot ?
                      'none'
                      : 'flex'
                    }}
                  >
                    <Edit id="exitTime" />
                  </Styled.SelectInputFieldDDown>
                  {exittimepicker ? (
                    <AnimatePresence>
                      <Styled.SelectInputFieldOptionsWrapper
                        as={motion.div}
                        initial={{
                          opacity: 0.5,
                          scale: 0.8
                        }}
                        animate={{
                          opacity: 1,
                          scale: 1
                        }}
                        exit={{
                          opacity: 0,
                          scale: 0.8
                        }}
                        transition={{
                          type: "spring",
                          duration: 0.3
                        }}
                      >
                        {exitTimeOptions.map((m) => (
                          <Styled.SelectInputFieldOption
                            onClick={() => handleSelectInputClick(m)}
                            key={m.id}
                          >
                            <p>{m.name}</p>
                          </Styled.SelectInputFieldOption>
                        ))}
                      </Styled.SelectInputFieldOptionsWrapper>
                    </AnimatePresence>
                  ) : null}
                </Styled.SelectInputField>
              </Styled.RowDataWrapper>
            </div>
            <Styled.CTAWrapper>
              <Styled.CTAButton as={motion.div} whileTap={{ scale: 0.9 }} onClick={handleCancel} style={{ display: flowCall === 'summary' ? 'flex' : 'none' }} >
                <p>cancelar</p>
              </Styled.CTAButton>
              <Styled.CTAButton as={motion.div} whileTap={{ scale: 0.9 }} onClick={handleDelete} style={{ display: flowCall === 'own' ? 'flex' : 'none' }} >
                <p>Borrar</p>
              </Styled.CTAButton>
              <Styled.CTAButton
                color={"featured"}
                as={motion.div}
                whileTap={{ scale: 0.9 }}
                onClick={handleNext}
                style={{ display: flowCall === 'summary' ? 'flex' : 'none' }}
              >
                <p>Guardar</p>
              </Styled.CTAButton>
              <Styled.CTAButton
                color={"featured"}
                as={motion.div}
                whileTap={{ scale: 0.9 }}
                onClick={handleUpdate}
                style={{ display: flowCall === 'own' ? 'flex' : 'none' }}
              >
                <p>Guardar Cambios</p>
              </Styled.CTAButton>
            </Styled.CTAWrapper>
          </>
        )}
      </AnimatePresence>
    </Styled.SelectInputWrapper>
  );
};

export default Summary;
