import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'

import './Slider.scss'
import { CircledRight, CircledLeft } from "../../assets/icons/Index"

const Slider = ({sources}) => {
  const [images] = useState(sources);
  const [index, setIndex] = useState(0);
  const left = useAnimation();
  const right = useAnimation();

  const handleImageDirection = (direction) => {
    let newIndex = 0;
    if(direction > 0) {
        newIndex = index + direction > images.length - 1 ? 0 : index + direction;
    } else {
        newIndex = index + direction < 0 ? images.length - 1 : index + direction;
    }

    setIndex(newIndex);
  };

  const handleKeyUp = (e) => {
    if (e.key === "ArrowLeft") {
        handleImageDirection(-1);
        const sequence = async () => {
            await left.start({ x:-10, transition: { duration: 0.2 }});
            return await left.start({ x: 0 });
        };

        left.start(sequence);
    }
    if (e.key === "ArrowRight") {
        handleImageDirection(1);
        const sequence = async () => {
            await right.start({ x: 10, transition: { duration: 0.2 }});
            return await right.start({ x: 0});
        };

        right.start(sequence);
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [index]);

  return (
    <div className="container">
        <motion.img
            className="image"
            src={images[index]}
            key={images[index]}
            initial={{
                opacity: 0,
                scale: 0.95
            }}
            animate={{
                opacity: 1,
                scale: 1
            }}
            alt="Facilities Slideshow"
        >
        </motion.img>
        <div className="controls">
            <motion.div
                className="leftControl"
                whileTap={{
                    x: -10
                }}
                animate={left}
                onClick={() => handleImageDirection(-1)}
            >
                <CircledLeft />
            </motion.div>
            <motion.div
                className="rightControl"
                whileTap={{
                    x: 10
                }}
                animate={right}
                onClick={() => handleImageDirection(1)}
            >
                <CircledRight />
            </motion.div>
        </div>
    </div>
  )
}

export default Slider