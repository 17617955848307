import * as React from "react";

const Grill = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 4.5s-3.364 3.618-4 5.95H3v1c0 2.65 1.443 4.954 3.627 6.402l1.789.93c.81.313 1.678.524 2.584.614h2c.91-.09 1.78-.303 2.594-.619l1.789-.931C19.562 16.397 21 14.096 21 11.449v-1h-5C15.364 8.12 12 4.5 12 4.5Zm0 3.95s1.083 1.215 1.295 2h-2.59c.212-.785 1.295-2 1.295-2Zm-6.766 4h13.532c-.568 2.776-3.248 5-6.766 5s-6.198-2.224-6.766-5Z"
      fill="currentColor"
    />
  </svg>
);

export default Grill;
