import * as React from "react";

const ReflectorBulb = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 2c-2.02 0-3.893.239-5.393.979S4 5.152 4 7c0 1.754 1.012 3.231 1.934 4.736.921 1.505 1.808 3.025 2.017 4.553.089.652.5 1.185 1.049 1.475V20c0 1.093.907 2 2 2h2c1.093 0 2-.907 2-2v-2.236c.549-.29.96-.824 1.049-1.477.21-1.528 1.096-3.046 2.017-4.55C18.988 10.231 20 8.753 20 7c0-1.848-1.108-3.282-2.607-4.021C15.893 2.239 14.019 2 12 2Zm0 2c1.847 0 3.474.261 4.508.771C17.542 5.281 18 5.848 18 7c0 .883-.713 2.18-1.639 3.691-.923 1.507-2 3.24-2.289 5.309H9.928c-.289-2.068-1.366-3.802-2.29-5.309C6.714 9.18 6 7.883 6 7c0-1.152.458-1.718 1.492-2.229C8.526 4.261 10.153 4 12 4Zm-1 14h2v2h-2v-2Z"
      fill="currentColor"
    />
  </svg>
);

export default ReflectorBulb;
