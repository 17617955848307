import { useState } from 'react'
import { supabase } from '../../supabaseClient'

import { LogoHorizontal, FacebookCircled, Twitter } from '../../assets/icons/Index'
import './Auth.scss'

export default function Auth({ session }) {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    // const [username, setUserName] = useState(null);
    // const [phone, setPhone] = useState(null);
    // const [avatar_url, setAvatarUrl] = useState(null);

    const handleEmailLogin = async (e) => {
        e.preventDefault()

        try {
            setLoading(true);
            const { error } = await supabase.auth.signInWithOtp({ email })
            if (error) throw error
            alert('Revisa tu correo con el link de ingreso')
        } catch (error) {
            alert(error.error_description || error.message)
        } finally {
            setLoading(false)
        }
    }

    const handleFBLogin = async () => {
       try {
            setLoading(true);
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'facebook',
                options: {
                    scopes: 'email, public_profile',
                    queryParams: {
                        picture: {
                            data: {
                                weight: 9999,
                                heigh: 9999,
                            }
                        }
                    },
                    redirectTo: 'https://eljardindelosvolcanes.com/'
                },
            })

       } catch (error) {
        alert(error.error_description || error.message)
       } finally {
        setLoading(false)
       }
    }

    

    // const handleTWLogin = async () => {
    //     const { data, error } = await supabase.auth.signInWithOAuth({
    //         provider: 'twitter',
    //     })
    // }

    return (
        <div className='auth'>
            <div className='auth__topbar'>
                <LogoHorizontal />
            </div>
            <form className='auth__form' onSubmit={handleEmailLogin}>
                <div className='authform__header'>
                    <h1>
                        ingresar
                    </h1>
                    <p>
                        Ingresa con tu correo o a través de Facebook.
                    </p>
                </div>
                {loading ? (
                    <p className='authform__loading'>
                        Verificar correo
                    </p>
                ) : (
                        <>
                        <input
                            id='email'
                            className='authform__input'
                            type="email"
                            placeholder='Ingresa tu correo...'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                            <button className='authform__submit'>
                                Enviar link mágico
                            </button>
                            <div className='oauthoptions_wrapper'>
                                <div className='authform__oauthlogin' onClick={handleFBLogin}>
                                    Facebook
                                    <FacebookCircled /> 
                                </div>
                            </div>
                        </>
                )}
            </form>
        </div>
    )
}