import * as React from "react";

const Weber = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M7 2v3h2V2H7Zm4 0v3h2V2h-2Zm4 0v3h2V2h-2ZM4 6v1c0 2.656 1.669 4.905 4.078 6.094L4 22h2.172l1.844-4h7.955l.394.852a2 2 0 1 0 1.814-.842l-2.275-4.907C18.324 11.918 20 9.664 20 7V6H4Zm2.24 2h11.52c-.564 2.22-2.802 4-5.76 4-2.958 0-5.196-1.78-5.76-4Zm3.73 5.762c.653.15 1.33.238 2.03.238.691 0 1.362-.085 2.008-.232L15.043 16H8.938l1.033-2.238Z"
      fill="currentColor"
    />
  </svg>
);

export default Weber;
