import * as React from "react"

const LongLeftarrow = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M25.334 10.667 30.667 16l-5.333 5.333v-4h-24v-2.666h24v-4Z"
      fill="currentColor"
    />
  </svg>
)

export default LongLeftarrow
