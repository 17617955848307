import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import { supabase } from '../../supabaseClient'
import { motion } from 'framer-motion'

import *  as Styles from './BlogStyles'
import './Blog.scss'
import { LongLeftArrow} from '../../assets/icons/Index'

const Blog = () => {
  dayjs.locale('es');
  const [loading, isLoading] = useState(true);
  const[imgstatus, setImgStatus] = useState(false);
  const [posts, setPosts] = useState();
  
  useEffect(() => {
    getPosts()
  }, [])

  const getPosts = async () => {
    isLoading(true);
    try {
        let { data, error, status } = await supabase
            .from('blog_posts')
            .select(`
                *
            `)
            if (data.length === 0) {
                setPosts=([])
            } else {
                setPosts(data)
            }
            if(error) {
                throw error
            }
    }
    catch(error) {
        alert(error.message)
    }
    finally {
        isLoading(false);
    }
  }

  return (
    <>
        {
            loading
            ? (
                <svg className='loader__container' viewBox="25 25 50 50">
                    <circle className='loader__stroke' r="20" cy="50" cx="50"></circle>
                </svg>
            )
            : (
                <div className='blog'>
                    {
                        posts.map((m) => (
                            <NavLink key={m.id} className='post_card' to={`/blog/${m.slug}`}>
                                <header className='post_card-header'>
                                    <h3 className='pcheader_title'>
                                        {m.title}
                                    </h3>
                                    <div className='pcheader_time'>
                                        <p className='pcheader_time-label'>
                                            Fecha de Publicación:
                                        </p>
                                        <p className='pcheader_time-value'>
                                            {
                                                dayjs(m.created_at).format('DD MMMM, YYYY')
                                            }
                                        </p>
                                    </div>
                                </header>
                                <div className='pc_content'>
                                    <motion.div className='pc_content-heroimg'
                                        whileTap={{
                                            scale: 0.9
                                        }}
                                    >
                                        <motion.img onLoad={() => setImgStatus(true)} src={m.hero_img} alt="Post Card Hero Image" 
                                            whileHover={{
                                                scale: 1.1
                                            }}
                                        />
                                        <div className='chats-loader' style={{ display: imgstatus ? 'none' : 'flex', marginLeft: 'unset' }} ></div>
                                    </motion.div>
                                    <div className='pc_content-excerpt'>
                                        <p className='pccontent_excerpt-text'>
                                            {m.excerpt}
                                        </p>
                                        <div className='pccontent_excerpt-cta'>
                                            <p>
                                                Ver Más
                                            </p>
                                            <LongLeftArrow />
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        ))
                    }
                </div>
            )
        }
    </>
  )
}

export default Blog