import * as React from "react";

const CalendarPlus = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6 2.25v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-14c0-1.103-.897-2-2-2h-1v-2h-2v2H8v-2H6Zm-1 4h14v2H5v-2Zm0 4h14l.002 10H5v-10Zm6 2v2H9v2h2v2h2v-2h2v-2h-2v-2h-2Z"
      fill="currentColor"
    />
  </svg>
);

export default CalendarPlus;
