import * as React from "react"

const SendMessage = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m5.029 10.8 2.867 2.866a.777.777 0 0 0 1.044.05l7.67-6.325-6.325 7.67a.778.778 0 0 0 .05 1.044l2.867 2.867a.778.778 0 0 0 1.28-.285l4.67-12.842a.777.777 0 0 0-.996-.996L5.313 9.519a.778.778 0 0 0-.284 1.28Z"
      fill="currentColor"
    />
  </svg>
)

export default SendMessage
