import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useEffect } from "react";
import ScrollContainer from 'react-indiana-drag-scroll'

import Slider from "../../components/Slider/Slider";
import "./Home.scss";
import { AnimalShelter, Bedroom, BunkBed, CalendarPlus, DiningTable, DownArrow, ElectricPlant, FloorMattress, Grill, HealthCalendar, Info, Landscape, LogCabin, Parking, PlayGround, ReflectorBulb, Relax, Sharing, SingleBed, Smoking, Sofa, SortDown, Tent, Weber, BathRoom, ReservationForm } from "../../assets/icons/Index"
import { Cabin1, Cabin2, Cabin3, Cabin4, Cabin5, Cabin6, Cabin7, Cabin8, Camping1, Camping2, Camping3, Camping4, Camping5, Camping6, Camping7, HeroSection, Intro1, Intro2, Intro3, Intro4, Kitchen1, Kitchen2, Kitchen3, Kitchen4, Kitchen5, Kitchen6, Kitchen7, Kitchen8, Kitchen9, Lookout1, Lookout2, Lookout3, Lookout4, Lookout5, Lookout6, PlayGround1, PlayGround2, PlayGround3, PlayGround4, PlayGround5, PlayGround6, Relaxing1, Relaxing2, Relaxing3, Relaxing4, Relaxing5, Relaxing6, Relaxing7, Sharing1, Sharing2, Sharing3, Sharing4, Sharing5, Sharing6, Sharing7, Sharing8, HeroSectionAlt, Intro2Alt, Intro3Alt, Intro4Alt, HeroBGAlt2, GalleryRow3Img1, GalleryRow3Img2 } from "../../assets/images/Index";
import Contact from "../../assets/icons/Contact";
import { NavLink } from "react-router-dom";

const CTAButton = styled(NavLink)`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background-color: var(--primary-color);
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  text-decoration: none;

  p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(0.75em, 1em, 1vw);
    text-align: right;
    text-transform: uppercase;
    color: var(--neutral-light);
    background: none;
  }

  svg {
    font-size: clamp(0.75em, 1em, 1vw);
    color: var(--neutral-light);
    background: none;
  }
`

const Home = () => {

  const defValue = [
    { id: 1, name: 'cabañas', icon: <LogCabin />, photos: [Cabin1, Cabin2, Cabin3, Cabin4, Cabin5, Cabin6, Cabin7, Cabin8] }
  ]

  const [data, setData] = useState(defValue);

  const value = () => {
    const newCategory = data.map(obj => {
      setOpened(!open);
      // obj.id= category;
      if (obj.id === 1) {
        return { ...obj, name: 'cabañas', icon: <LogCabin />, photos: [Cabin1, Cabin2, Cabin3, Cabin4, Cabin5, Cabin6, Cabin7, Cabin8] }
      } else if (obj.id === 2) {
        return { ...obj, name: 'camping', icon: <Tent />, photos: [Camping1, Camping2, Camping3, Camping4, Camping5, Camping6, Camping7] }
      } else if (obj.id === 3) {
        return { ...obj, name: 'cocina', icon: <DiningTable />, photos: [Kitchen1, Kitchen2, Kitchen3, Kitchen4, Kitchen5, Kitchen6, Kitchen7, Kitchen8, Kitchen9] }
      } else if (obj.id === 4) {
        return { ...obj, name: 'relajación', icon: <Relax />, photos: [Relaxing1, Relaxing2, Relaxing3, Relaxing4, Relaxing5, Relaxing6, Relaxing7] }
      } else if (obj.id === 5) {
        return { ...obj, name: 'miradores', icon: <Landscape />, photos: [Lookout1, Lookout2, Lookout3, Lookout4, Lookout5, Lookout6] }
      } else if (obj.id === 6) {
        return { ...obj, name: 'convivio', icon: <Sharing />, photos: [Sharing1, Sharing2, Sharing3, Sharing3, Sharing4, Sharing5, Sharing6, Sharing7, Sharing8] }
      } else if (obj.id === 7) {
        return { ...obj, name: 'juegos', icon: <PlayGround />, photos: [PlayGround1, PlayGround2, PlayGround3, PlayGround4, PlayGround5, PlayGround6] }
      }
      return obj;
    });
    setData(newCategory);
  }

  const [open, setOpened] = useState(false);

  return (
    data.map(obj => {
      return (
        <div key={obj.id} className="inicio">
          <div className="inicio__herosection">
            <motion.img src={HeroSection} alt="Hero Section" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1.2, ease: 'easeOut' }} viewport={{ once: true }} />
            <motion.div
              initial={{
                opacity: 0,
                x: 80
              }}
              whileInView={{
                opacity: 1,
                x: 0
              }}
              transition={{
                type: "spring",
                stiffness: 86,
                damping: 32,
                mass: 3.5,
              }}
              viewport={{ once: true }}
            >
              <h1>el jardín de los volcanes</h1>
            </motion.div>
            <motion.div
              className="downarrow"
              initial={{
                opacity: 0,
                y: -80
              }}
              whileInView={{
                opacity: 1,
                y: 0
              }}
              transition={{
                type: "spring",
                stiffness: 140,
                damping: 51,
                mass: 1
              }}
              viewport={{
                once: true
              }}
            >
              <DownArrow />
            </motion.div>
          </div>
          <div className="inicio__introsection">
            <div className="introsection__featured-right">
              <div>
                <motion.h2
                  initial={{
                    opacity: 0,
                    x: -80
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  experiencias mágicas
                </motion.h2>
                <motion.p
                  initial={{
                    opacity: 0,
                    x: -40
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1,
                    delay: 0.8
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  Si amas la naturaleza, sin duda disfrutaras de una experiencia única y mágica en El Jardín de Los Volcanes. Con bosques llenos de vida, vistas inspiradoras y cielos mágicos, todo esto junto a las personas que más quieres. Porque cuando estas más cerca de la naturaleza;  más cerca estas de la mágia.
                </motion.p>
              </div>
              <motion.div
                initial={{
                  y: 40,
                  opacity: 0
                }}
                whileInView={{
                  y: 0,
                  opacity: 1
                }}
                transition={{
                  type: "spring",
                  stiffness: 140,
                  damping: 51,
                  mass: 1
                }}
                viewport={{
                  once: true
                }}
              >
                <img src={Intro1} alt="Breathing Forest" />
              </motion.div>
            </div>
            <div className="introsection__featured-left">
              <motion.div
                initial={{
                  y: 40,
                  opacity: 0
                }}
                whileInView={{
                  y: 0,
                  opacity: 1
                }}
                transition={{
                  type: "spring",
                  stiffness: 140,
                  damping: 51,
                  mass: 1,
                  delay: 0.2
                }}
                viewport={{
                  once: true
                }}
              >
                <img src={Intro2Alt} alt="Magic Bonfire" />
              </motion.div>
              <div>
                <motion.h2
                  initial={{
                    opacity: 0,
                    x: 20
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  momentos inolvidables
                </motion.h2>
                <motion.p
                  initial={{
                    opacity: 0,
                    x: 20
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1,
                    delay: 0.6
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  Date un respiro de la vida en la ciudad y experimenta de forma única los cielos de color purpura y de oro de nuestro increíble pulgarcito. Reconectate con la naturaleza y disfruta de momentos inolvidables junto a tu familia y a las personas que más quieres. Algo que es seguro es que si tienes mascotas, lo disfrutaran!
                </motion.p>
              </div>
            </div>
            <div className="introsection__featured-right">
              <div>
                <motion.h2
                  initial={{
                    opacity: 0,
                    x: -80
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  vistas vibrantes
                </motion.h2>
                <motion.p
                  initial={{
                    opacity: 0,
                    x: -40
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1,
                    delay: 0.6
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  Imagina amanecer y ver desde tu ventana el volcan Ilamatepec, salir de la cabaña y ver un Jardín de Los Volcanes. Imagina experimentar verdaderos cielos mágicos en la noche, llenos de estrellas y resaltados por el contraste de bosques frondosos y volcanes soberbios. Esto es El Jardín de Los Volcanes.
                </motion.p>
              </div>
              <motion.div
                initial={{
                  y: 40,
                  opacity: 0
                }}
                whileInView={{
                  y: 0,
                  opacity: 1
                }}
                transition={{
                  type: "spring",
                  stiffness: 140,
                  damping: 51,
                  mass: 1,
                  delay: 0.3
                }}
                viewport={{
                  once: true
                }}
              >
                <img src={Intro3Alt} alt="Starry Night" />
              </motion.div>
            </div>
            <div className="introsection__featured-left">
              <motion.div
                initial={{
                  y: 40,
                  opacity: 0
                }}
                whileInView={{
                  y: 0,
                  opacity: 1
                }}
                transition={{
                  type: "spring",
                  stiffness: 140,
                  damping: 51,
                  mass: 1,
                  delay: 0.3
                }}
                viewport={{
                  once: true
                }}
              >
                <video className="video__container" autoPlay loop muted>
                  <source src={Intro4Alt} type='video/mp4' />
                </video>
              </motion.div>
              <div>
                <motion.h2
                  initial={{
                    opacity: 0,
                    x: 20
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  naturaleza inspiradora
                </motion.h2>
                <motion.p
                  initial={{
                    opacity: 0,
                    x: 20
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1,
                    delay: 0.4
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  Que mejor forma de impulsar tu creatividad y de darle un respiro a tu mente que rodearte de la naturaleza, flores, orquideas y vegetación mágica; bosques y vientos revitalizadores;  vistas y cielos asombrosos. Disfruta de experiencias mágicas, únicas y EXCLUSIVAS para los que más quieres.
                </motion.p>
              </div>
            </div>
          </div>
          <div className="inicio__instalaciones">
            <div className="instalaciones__heading">
              <motion.h2
                initial={{
                  opacity: 0,
                  x: -40
                }}
                whileInView={{
                  opacity: 1,
                  x: 0
                }}
                transition={{
                  type: "spring",
                  stiffness: 140,
                  damping: 51,
                  mass: 1
                }}
                viewport={{
                  once: true
                }}
              >
                instalaciones
              </motion.h2>
              <motion.div
                className="heading__picker-container"
                initial={{
                  opacity: 0
                }}
                whileInView={{
                  opacity: 1
                }}
                transition={{
                  duration: 1,
                  ease: 'easeOut',
                  delay: 0.6
                }}
                viewport={{
                  once: true
                }}
              >
                <motion.div
                  className="picker__selected"
                  onClick={() => setOpened(!open)}
                  whileveHover={{
                    scale: 1.1
                  }}
                  whileTap={{
                    scale: 0.9
                  }}
                >
                  <SortDown />
                  <div className="picker__selected-content">
                    <p>
                      {obj.name}
                    </p>
                    {obj.icon}
                  </div>
                </motion.div>
                {
                  open && (
                    <motion.div
                      className="picker__dropdown"
                      style={{
                        display: open === true ? "flex" : 'none',
                      }}
                      onClick={value}
                      initial={{
                        opacity: 0.5,
                        scale: 0.5
                      }}
                      animate={{
                        opacity: 1,
                        scale: 1
                      }}
                      exit={{
                        opacity: 0,
                        scale: 0.8
                      }}
                      transition={{
                        type: 'spring',
                        duration: 0.3
                      }}
                    >
                      <div
                        onClick={() => value(obj.id = 1)}
                        style={{
                          display: obj.id === 1 ? 'none' : 'flex',
                        }}
                      >
                        <p>
                          cabañas
                        </p>
                        <LogCabin />
                      </div>
                      <div
                        onClick={() => value(obj.id = 2)}
                        style={{
                          display: obj.id === 2 ? 'none' : 'flex',
                        }}
                      >
                        <p>
                          camping
                        </p>
                        <Tent />
                      </div>
                      <div
                        onClick={() => value(obj.id = 3)}
                        style={{
                          display: obj.id === 3 ? 'none' : 'flex',
                        }}
                      >
                        <p>
                          cocina
                        </p>
                        <DiningTable />
                      </div>
                      <div
                        onClick={() => value(obj.id = 4)}
                        style={{
                          display: obj.id === 4 ? 'none' : 'flex',
                        }}
                      >
                        <p>
                          relajación
                        </p>
                        <Relax />
                      </div>
                      <div
                        onClick={() => value(obj.id = 5)}
                        style={{
                          display: obj.id === 5 ? 'none' : 'flex',
                        }}
                      >
                        <p>
                          miradores
                        </p>
                        <Landscape />
                      </div>
                      <div
                        onClick={() => value(obj.id = 6)}
                        style={{
                          display: obj.id === 6 ? 'none' : 'flex',
                        }}
                      >
                        <p>
                          convivio
                        </p>
                        <Sharing />
                      </div>
                      <div
                        onClick={() => value(obj.id = 7)}
                        style={{
                          display: obj.id === 7 ? 'none' : 'flex',
                        }}
                      >
                        <p>
                          juegos
                        </p>
                        <PlayGround />
                      </div>
                    </motion.div>
                  )
                }
              </motion.div>
            </div>
            <motion.div
              className="instalaciones__tarjeta"
              initial={{
                y: -80,
                opacity: 0
              }}
              whileInView={{
                y: 0,
                opacity: 1
              }}
              transition={{
                type: "spring",
                stiffness: 140,
                damping: 51,
                mass: 1
              }}
              viewport={{
                once: true
              }}
            >
              <div className="tarjeta__slider">
                <Slider sources={obj.photos} />
              </div>
              <ScrollContainer className="tarjeta__content">
                <div className="content__info">
                  <div className="info__heading">
                    <div>
                      <h3>
                        375 personas
                      </h3>
                      <div></div>
                      <h3>
                        3 cabañas
                      </h3>
                      <div></div>
                      <h3>
                        19 camas
                      </h3>
                      <div></div>
                      <h3>
                        11 baños
                      </h3>
                    </div>
                    <div>
                      <p>
                        Somos un lugar mágico, con una ubicación privilegiada, rodeado de bosques y neblina deonde pasaras una experiencia inolvidable. Alquila 1.5 manzanas de terreno solo para ti y tu grupo! Hay espacio para 375 personas acampando.
                      </p>
                    </div>
                  </div>
                  <ScrollContainer className="info__minicards">
                    <div className="minicards">
                      <div>
                        <h4>
                          cabaña 1
                        </h4>
                      </div>
                      <div>
                        <Bedroom />
                        <SingleBed />
                        <BunkBed />
                        <BathRoom />
                      </div>
                      <div>
                        <p>
                          1 Cama Doble
                        </p>
                        <p>
                          2 Camas Personales
                        </p>
                        <p>
                          1 Camarote
                        </p>
                        <p>
                          1 Baño Completo
                        </p>
                      </div>
                    </div>
                    <div className="minicards">
                      <div>
                        <h4>
                          cabaña 2
                        </h4>
                      </div>
                      <div>
                        <Bedroom />
                        <Sofa />
                        <FloorMattress />
                        <BathRoom />
                      </div>
                      <div>
                        <p>
                          1 Cama Doble
                        </p>
                        <p>
                          1 Cama Sofá
                        </p>
                        <p>
                          5 Colchones
                        </p>
                        <p>
                          1 Baño Completo
                        </p>
                      </div>
                    </div>
                    <div className="minicards">
                      <div>
                        <h4>
                          cabaña 3
                        </h4>
                      </div>
                      <div>
                        <SingleBed />
                        <BunkBed />
                      </div>
                      <div>
                        <p>
                          1 Cama Personal
                        </p>
                        <p>
                          2 Camarotes
                        </p>
                      </div>
                    </div>
                  </ScrollContainer>
                  <ScrollContainer className="info__services">
                    <div>
                      <div>
                        <p>
                          mirador
                        </p>
                        <Landscape />
                      </div>
                      <div>
                        <p>
                          fogata
                        </p>
                        <Grill />
                      </div>
                      <div>
                        <p>
                          eventos
                        </p>
                        <HealthCalendar />
                      </div>
                      <div>
                        <p>
                          baños exteriores x8
                        </p>
                        <BathRoom />
                      </div>
                    </div>
                    <div>
                      <div>
                        <p>
                          cocina
                        </p>
                        <Weber />
                      </div>
                      <div>
                        <p>
                          electricidad (energía solar)
                        </p>
                        <ReflectorBulb />
                      </div>
                      <div>
                        <p>
                          planta eléctrica (para apoyar)
                        </p>
                        <ElectricPlant />
                      </div>
                      <div>
                        <p>
                          comedor exterior x2
                        </p>
                        <DiningTable />
                      </div>
                    </div>
                    <div>
                      <div>
                        <p>
                          parqueo gratis
                        </p>
                        <Parking />
                      </div>
                      <div>
                        <p>
                          pet friendly
                        </p>
                        <AnimalShelter />
                      </div>
                      <div>
                        <p>
                          fumar permitido
                        </p>
                        <Smoking />
                      </div>
                      <div>
                        <p>
                          urinario x1
                        </p>
                        <BathRoom />
                      </div>
                    </div>
                  </ScrollContainer>
                  <div className="content__ctas" >
                    <motion.div
                      style={{
                        background: 'none',
                        width: 'fit-content',
                        height: 'fit-content'
                      }}
                      whileHover={{
                        scale: 1.1
                      }}
                      whileTap={{
                        scale: 0.9
                      }}
                    >
                      <CTAButton
                        to="/chat"
                      >
                        <p>
                          contactar
                        </p>
                        <Contact />
                      </CTAButton>
                    </motion.div>
                  </div>
                </div>

              </ScrollContainer>
            </motion.div>
          </div>
          <div className="inicio__actividades">
            <div className="actividades__heading">
              <motion.h2
                initial={{
                  x: -40,
                  opacity: 0
                }}
                whileInView={{
                  opacity: 1,
                  x: 0
                }}
                transition={{
                  type: "spring",
                  stiffness: 140,
                  damping: 51,
                  mass: 1
                }}
                viewport={{
                  once: true
                }}
              >
                actividades
              </motion.h2>
            </div>
            <div className="actividades__grid-container">
              <div className="grid__container-row1">
                <motion.div
                  initial={{
                    y: -20,
                    opacity: 0
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  <motion.img whileHover={{ scale: 1.1 }} src={GalleryRow3Img1} alt="Camping Activities Example 1" />
                </motion.div>
                <motion.div
                  initial={{
                    y: -20,
                    opacity: 0
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1,
                    delay: 0.2,
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  <motion.img whileHover={{ scale: 1.1 }} src={Camping6} alt="Camping Activities Example 2" />
                </motion.div>
                <motion.div
                  initial={{
                    y: 20,
                    opacity: 0
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1,
                    delay: 0.4,
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  <motion.img whileHover={{ scale: 1.1 }} src={Camping1} alt="Camping Activities Example 1" />
                </motion.div>
              </div>
              <div className="grid__container-row2">
                <motion.div
                  initial={{
                    y: -20,
                    opacity: 0
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1,
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  <motion.img whileHover={{ scale: 1.1 }} src={Sharing2} alt="Sharing Activities Example 1" />
                </motion.div>
                <motion.div
                  initial={{
                    y: -20,
                    opacity: 0
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1,
                    delay: 0.2
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  <motion.img whileHover={{ scale: 1.1 }} src={Sharing6} alt="Sharing Activities Example 2" />
                </motion.div>
                <motion.div
                  initial={{
                    y: 20,
                    opacity: 0
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1,
                    delay: 0.4
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  <motion.img whileHover={{ scale: 1.1 }} src={Sharing7} alt="Sharing Activities Example 1" />
                </motion.div>
              </div>
              <div className="grid__container-row3">
                <motion.div
                  initial={{
                    y: -20,
                    opacity: 0
                  }}
                  whileInView={{
                    y: 0,
                    opacity: 1
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 140,
                    damping: 51,
                    mass: 1,
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  <motion.img whileHover={{ scale: 1.1 }} src={Camping3} alt="Lookout Activities Example 1" />
                </motion.div>
                <div>
                  <motion.div
                    initial={{
                      y: -20,
                      opacity: 0
                    }}
                    whileInView={{
                      y: 0,
                      opacity: 1
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 140,
                      damping: 51,
                      mass: 1,
                      delay: 0.3,
                    }}
                    viewport={{
                      once: true
                    }}
                  >
                    <motion.img whileHover={{ scale: 1.1 }} src={Lookout5} alt="Lookout Activities Example 2" />
                  </motion.div>
                  <motion.div
                    initial={{
                      y: 20,
                      opacity: 0
                    }}
                    whileInView={{
                      y: 0,
                      opacity: 1
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 140,
                      damping: 51,
                      mass: 1,
                      delay: 0.5,
                    }}
                    viewport={{
                      once: true
                    }}
                  >
                    <motion.img whileHover={{ scale: 1.1 }} src={GalleryRow3Img2} alt="Lookout Activities Example 3" />
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          <div className="inicio__ubicacion">
            <div className="ubicacion__heading">
              <motion.h2
                initial={{
                  x: -40,
                  opacity: 0
                }}
                whileInView={{
                  x: 0,
                  opacity: 1
                }}
                transition={{
                  type: "spring",
                  stiffness: 140,
                  damping: 51,
                  mass: 1,
                }}
                viewport={{
                  once: true
                }}
              >
                ubicación
              </motion.h2>
            </div>
            <motion.div
              className="ubicacion__map"
              initial={{
                y: -40,
                opacity: 0
              }}
              whileInView={{
                y: 0,
                opacity: 1
              }}
              transition={{
                type: "spring",
                stiffness: 140,
                damping: 51,
                mass: 1,
                delay: 0.3
              }}
              viewport={{
                once: true
              }}
            >
              <iframe title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5478.818211742153!2d-89.62626322740986!3d13.832462168192599!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x26bb53d2a1d09f38!2sEl%20Jardin%20de%20Los%20Volcanes!5e0!3m2!1sen!2s!4v1665566076886!5m2!1ses!2s" width="100%" height="100%" allowFullScreen="yes" loading="lazy" referrerPolicy="no-referrer-when-downgrade" style={{ border: '0' }}></iframe>
            </motion.div>
          </div>
          <motion.div
            className="inicio__lastctas"
            initial={{
              y: -20,
              opacity: 0
            }}
            whileInView={{
              y: 0,
              opacity: 1
            }}
            transition={{
              type: "spring",
              stiffness: 140,
              damping: 51,
              mass: 1,
            }}
            viewport={{
              once: true
            }}
          >
            <motion.div
              style={{
                background: 'none',
                width: 'fit-content',
                height: 'fit-content'
              }}
              whileHover={{
                scale: 1.1
              }}
              whileTap={{
                scale: 0.9
              }}
            >
              <CTAButton
                to='/disponibilidad'
              >
                <p>
                  reservar
                </p>
                <ReservationForm />
              </CTAButton>
            </motion.div>
          </motion.div>
          <motion.div
            className="inicio__faqs"
            initial={{
              y: -40,
              opacity: 0
            }}
            whileInView={{
              y: 0,
              opacity: 1
            }}
            transition={{
              type: "spring",
              stiffness: 140,
              damping: 51,
              mass: 1,
            }}
            viewport={{
              once: true
            }}
          >
            <div className="faqs__box">
              <div className="faqs__box-question">
                <h3>
                  ¿Hay que llegar en vehículo 4x4?
                </h3>
                <p>
                  ¡No, no es necesario contar con un vehículo 4x4! La carretera que conduce a la propiedad ha sido recientemente pavimentada hasta el portón, garantizando un acceso sin problemas para todos los vehículos.
                </p>
              </div>
              <div className="faqs__box-question">
                <h3>
                  ¿Hay hora de entrada y/o llegada?
                </h3>
                <p>
                  <span style={{ background: 'none', color: '#15212D', fontWeight: '900' }}>12 Horas:</span> <br />
                  -- Entrada: 7AM <br />
                  -- Salida: 4PM <br />
                  <span style={{ background: 'none', color: '#15212D', fontWeight: '900' }}>24 Horas</span> <br />
                  <span style={{ background: 'none', color: '#15212D', fontWeight: '600' }}>-- Opción 1:</span> <br />
                  --- Entrada: 7AM <br />
                  --- Salida: 7AM <br />
                  <span style={{ background: 'none', color: '#15212D', fontWeight: '600' }}>-- Opción 2:</span> <br />
                  --- Entrada: 4PM <br />
                  --- Salida: 4PM
                </p>
              </div>
              <div className="faqs__box-question">
                <h3>
                  ¿Qué actividades se pueden hacer?
                </h3>
                <p>
                  Campamentos, Retiros, Carneadas, Convivios; el terreno es tuyo mientras lo alquilas, tu decides que hacer.
                </p>
              </div>
              <div className="faqs__box-question">
                <h3>
                  ¿Cuales son los precios?
                </h3>
                <p>
                  Los precios dependen del tiempo de estadia y el numero de personas, puedes contactarnos a través de nuestro 👉 <a style={{ textDecoration: 'underline', background: 'none', color: '#15212D', }} href="https://eljardindelosvolcanes.com/chat" aria-label="EJDLV - Chat" rel="noopener noreferrer" target="_self">Chat</a>  y consultar 😃.
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      )
    })
  );
};

export default Home;