import * as React from "react";

const FacebookCircled = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 2C6.489 2 2 6.489 2 12s4.489 10 10 10 10-4.489 10-10S17.511 2 12 2Zm0 2c4.43 0 8 3.57 8 8a7.977 7.977 0 0 1-6.781 7.898v-5.513h2.328l.365-2.365H13.22v-1.293c0-.983.32-1.856 1.24-1.856h1.476V6.807c-.26-.035-.808-.112-1.845-.112-2.166 0-3.436 1.144-3.436 3.75v1.575H8.428v2.365h2.226v5.494A7.978 7.978 0 0 1 4 12c0-4.43 3.57-8 8-8Z"
      fill="currentColor"
    />
  </svg>
);

export default FacebookCircled;
