import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import { supabase } from '../../../supabaseClient'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet'

import './BlogPost.scss'
import { CircledLeft } from '../../../assets/icons/Index'

const BlogPost = () => {
  dayjs.locale('es');
  const { slug } = useParams();
  const [loading, isLoading] = useState(true);
  const[imgstatus, setImgStatus] = useState(false);
  const [post, setPost] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getPost()
  }, [])

  const getPost = async () => {
    isLoading(true);
    try {
        let { data, error, status } = await supabase
            .from('blog_posts')
            .select(`
                *
            `)
            .eq('slug', slug)
            if (data.length === 0) {
                setPost=([])
            } else {
                setPost(data)
            }
            if(error) {
                throw error
            }
    }
    catch(error) {
        alert(error.message)
    }
    finally {
        isLoading(false);
    }
  }

  return (
    <>
      {
        loading ? (
          <svg className='loader__container' viewBox="25 25 50 50">
            <circle className='loader__stroke' r="20" cy="50" cx="50"></circle>
          </svg>
        )
        : (
          <div className='post'>
            <Helmet>
              <meta
              content={`${post[0].excerpt}`}
              property="og:description"
              />
              <meta property="og:type" content="image/webp" />
              <meta property="og:url" content={`https://eljardindelosvolcanes.com/blog/${post[0].slug}`} />
              <meta
                property="og:image"
                content={`${post[0].hero_img}`}
              />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content="@jardinvolcanes" />
              <meta name="viewport" content="width=device-width, initial-scale=1" />
              <meta name="theme-color" content="#483B32" />
              <meta
                name="description"
                content={`${post[0].excerpt}`}
              />
              <title>{`${post[0].title} - El Jardin de Los Volcanes`}</title>
            </Helmet>
            <header className='post__header'>
              <motion.div className='post__header-backbutton'
                whileHover={{
                  scale: 1.1
                }}
                whiletap={{
                  scale: 0.9
                }}
                onClick={() => navigate(-1)}
              >
                <CircledLeft />
              </motion.div>
              <div className='post__header-title'>
                <motion.h1 className='pheader__h1'
                  initial={{
                    x: -20,
                    opacity: 0.5
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0
                  }}
                  transition={{
                    type: "spring",
                    duration: 0.4
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  {post[0].title}
                </motion.h1>
                <motion.div className='pheader__label'
                   initial={{
                    x: -20,
                    opacity: 0.5
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0
                  }}
                  transition={{
                    type: "spring",
                    delay: 0.3,
                    duration: 0.4
                  }}
                  viewport={{
                    once: true
                  }}
                >
                  <p className='pheader_time-label'>
                    Fecha de Publicación:
                  </p>
                  <p className='pheader_time-value'>
                    {
                      dayjs(post[0].created_at).format('DD MMMM, YYYY')
                    }
                  </p>
                </motion.div>
              </div>
            </header>
            <motion.div className='post__heroimage '
               initial={{
                y: 20,
                opacity: 0.5
                }}
                whileInView={{
                  opacity: 1,
                  y: 0
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                viewport={{
                  once: true
                }}
            >
              <motion.img onLoad={() => setImgStatus(true)} src={post[0].hero_img} alt="Hero Image" 
                whileHover={{
                  scale: 1.1
                }}
              />
              <div className='chats-loader' style={{ display: imgstatus ? 'none' : 'flex', marginLeft: 'unset' }} ></div>
            </motion.div>
            <motion.div className='post__body' dangerouslySetInnerHTML={{__html: post[0].body}}
               initial={{
                y: 20,
                opacity: 0.5
                }}
                whileInView={{
                  opacity: 1,
                  y: 0
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                viewport={{
                  once: true
                }}
            >
            </motion.div>
          </div>
        )
      }
    </>
  )
}

export default BlogPost