import * as React from "react";

const HalfDay = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.51 1.429c6.302 0 11.429 5.127 11.429 11.428 0 6.302-5.127 11.429-11.429 11.429C5.21 24.286.082 19.159.082 12.857.082 6.556 5.209 1.43 11.51 1.43Zm9.143 11.428c0-4.6-3.415-8.418-7.843-9.05a1.148 1.148 0 0 0-1.3 1.14v15.82c0 .693.614 1.239 1.3 1.14 4.428-.631 7.843-4.45 7.843-9.05Z"
      fill="currentColor"
    />
  </svg>
);

export default HalfDay;
