import * as React from "react"

const Edit = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M19.172 2c-.724 0-1.448.276-2 .828L16 4l4 4 1.172-1.172a2.83 2.83 0 0 0-2-4.828ZM14.5 5.5 3 17v4h4L18.5 9.5l-4-4Z"
      fill="currentColor"
    />
  </svg>
)

export default Edit
