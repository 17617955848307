import * as React from "react";

const Sofa = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#a)">
      <path
        d="M7 4C5.355 4 4 5.355 4 7v1.182a2.824 2.824 0 0 0-.713-.168h-.004a3.022 3.022 0 0 0-1.709.347c-1.407.757-1.872 2.472-1.31 3.875l1.699 4.25A4.009 4.009 0 0 0 5 18.937V21h2v-2h10v2h2v-2.064a4.003 4.003 0 0 0 3.037-2.452l1.733-4.27.002-.001c.539-1.35.124-2.988-1.19-3.777A3.028 3.028 0 0 0 21.027 8c-.357 0-.702.07-1.027.19V7c0-1.645-1.355-3-3-3H7Zm0 2h10c.565 0 1 .435 1 1v3.475L16.973 13H7.03l-1.03-2.578V7c0-.565.434-1 1-1Zm14.027 4c.185 0 .351.044.524.148.396.239.564.82.363 1.323l-1.734 4.27v.001A1.995 1.995 0 0 1 18.322 17H5.678c-.82 0-1.552-.495-1.858-1.256v-.002l-1.699-4.248v-.002c-.211-.527-.024-1.141.4-1.369l.003-.002a.964.964 0 0 1 .574-.117c.346.033.716.333.887.76L5.678 15h12.64l1.776-4.365.002-.004a.998.998 0 0 1 .931-.631Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#EBF2FA" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Sofa;
