import styled from "styled-components";

export const SelectInputWrapper = styled.div`
  display: ${(props) => (props.visible ? "flex" : "none")};
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: fit-content;
  /* min-width: 320px; */
  height: fit-content;
  align-items: flex-start;
  gap: 1.5rem;
`;

export const SelectInputHeaderWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: fit-content;
  height: fit-content;
  align-items: flex-start;
  gap: 4px;
  overflow: hidden;
`;

export const SelectInputLabel = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  color: var(--neutral-dark_2);
  text-transform: capitalize;
`;

export const SelectInputDescription = styled.h3`
  font-style: normal;
  font-weight: 300;
  font-size: 0.75em;
  color: var(--neutral-dark_2);
`;

export const SelectInputField = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: left;
  border-radius: 8px;
  background-color: var(--neutral-light_3);
`;

export const SelectInputFieldValue = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  text-transform: capitalize;
  color: var(--neutral-dark_2);
`;

export const SelectInputFieldDDown = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  height: fit-content;
  padding: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 25%;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:hover {
    background-color: #b4bdc8;
  }

  svg {
    font-size: 1em;
    color: var(--neutral-dark_2);
    transform: ${(props) => (props.active ? `rotate(180deg)` : `rotate(0deg)`)};
    transition: all 0.3s ease-out;
  }
`;

export const SelectInputFieldOptionsWrapper = styled.div`
  display: "flex";
  position: absolute;
  top: 3rem;
  left: 0;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
  justify-content: left;
  /* padding: 4px; */
  border-radius: 8px;
  background-color: var(--neutral-dark_2);
  overflow: hidden;
  z-index: 2;
`;

export const SelectInputFieldOption = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
  justify-content: left;
  padding: 8px;
  /* border-radius: 6px; */
  transition: all 0.4s ease-out;

  &:hover {
    background-color: #566574;
  }

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 1em;
    color: var(--neutral-light);
    text-transform: capitalize;
  }
`;

export const CTAWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  justify-content: flex-end;
  gap: 1rem;
`;

export const CTAButton = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 6px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.color === "featured" ? "#374758" : "#B7CEE9"};
  border-radius: 6px;
  cursor: pointer;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 0.75em;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${(props) =>
      props.color === "featured" ? "var(--neutral-light_2)" : "#566574"};
  }
`;
