import * as React from "react";

const Day = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#a)">
      <path
        d="M12 0c-.6 0-1 .4-1 1v1c0 .6.4 1 1 1s1-.4 1-1V1c0-.6-.4-1-1-1ZM4.2 3.2c-.25 0-.5.1-.7.3-.4.4-.4 1 0 1.4l.7.7c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-.7-.7a.99.99 0 0 0-.7-.3Zm15.6 0a.99.99 0 0 0-.7.3l-.7.7c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l.7-.7c.4-.4.4-1 0-1.4-.2-.2-.45-.3-.7-.3ZM12 5a7 7 0 1 0 0 14 7 7 0 0 0 0-14ZM1 11c-.6 0-1 .4-1 1s.4 1 1 1h1c.6 0 1-.4 1-1s-.4-1-1-1H1Zm21 0c-.6 0-1 .4-1 1s.4 1 1 1h1c.6 0 1-.4 1-1s-.4-1-1-1h-1ZM4.9 18.1a.99.99 0 0 0-.7.3l-.7.7c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l.7-.7c.4-.4.4-1 0-1.4-.2-.2-.45-.3-.7-.3Zm14.2 0c-.25 0-.5.1-.7.3-.4.4-.4 1 0 1.4l.7.7c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-.7-.7a.99.99 0 0 0-.7-.3ZM12 21c-.6 0-1 .4-1 1v1c0 .6.4 1 1 1s1-.4 1-1v-1c0-.6-.4-1-1-1Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#EBF2FA" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default Day;
