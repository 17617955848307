import * as React from "react";

const Twitter = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M22 3.999c-.78.463-2.345 1.094-3.265 1.276-.027.007-.049.016-.075.023A4.5 4.5 0 0 0 11 8.499c0 .131-.011.372 0 .5-3.353 0-5.905-1.756-7.735-4-.199.5-.286 1.29-.286 2.032 0 1.401 1.095 2.777 2.8 3.63-.314.081-.66.139-1.02.139A2.686 2.686 0 0 1 3 10.183v.051c0 1.958 2.078 3.291 3.926 3.662-.375.221-1.131.243-1.5.243-.26 0-1.18-.119-1.426-.165.514 1.605 2.368 2.507 4.135 2.539-1.382 1.084-2.341 1.486-5.171 1.486H2C3.788 19.145 6.065 20 8.347 20 15.777 20 20 14.337 20 8.999l-.005-.447c0-.018.005-.035.005-.053 0-.027-.008-.053-.008-.08a18.384 18.384 0 0 0-.009-.329A8.217 8.217 0 0 0 22 5.999a8.07 8.07 0 0 1-2.32.636c.834-.5 2.019-1.692 2.32-2.636Zm-4 5c0 4.08-2.957 8.399-8.466 8.943.746-.529 1.466-1.28 1.466-1.28S8 14 7.775 10.522a11 11 0 0 0 3.225.477h2V8.498a2.5 2.5 0 0 1 5 .002v.499Z"
      fill="currentColor"
    />
  </svg>
);

export default Twitter;
