import * as React from "react";

const HealthCalendar = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6 1v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2v2H8V1H6ZM5 5h14v2H5V5Zm0 4h14v10H5V9Zm5.49 2c-.92.002-1.635.528-2.033 1.303a2.056 2.056 0 0 0-.236.931c-.015 1.396 1.411 2.6 2.017 3.112l.123.103c.506.441 1.641 1.168 1.641 1.168s1.135-.727 1.64-1.168l.122-.103c.606-.512 2.032-1.716 2.017-3.112a2.066 2.066 0 0 0-.236-.931c-.397-.775-1.11-1.302-2.03-1.303-.58-.001-1.251.33-1.511.705-.237-.374-.933-.706-1.514-.705Z"
      fill="currentColor"
    />
  </svg>
);

export default HealthCalendar;
