import * as React from "react"

const LockIcon = ({ title, titleId, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.172 4.172A4 4 0 0 1 16 7v1.07A7.963 7.963 0 0 0 12 7c-1.457 0-2.823.39-4 1.07V7a4 4 0 0 1 1.172-2.828ZM6 6.75h.005a6 6 0 0 1 11.99 0H18v2.958a8 8 0 1 1-12 0V6.75Zm7 8.982A2 2 0 0 0 12 12a2 2 0 0 0-1 3.732V19h2v-3.268ZM18 15a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"
            clipRule="evenodd"
        />
    </svg>
)
export default LockIcon
