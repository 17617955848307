import * as React from "react"

const Contact = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M9 3C5.134 3 2 5.686 2 9c0 1.127.369 2.177 1 3.078v3.639c0 .529.604.832 1.027.513l2.262-1.697c.833.3 1.75.467 2.711.467 3.866 0 7-2.686 7-6s-3.134-6-7-6Zm8.977 5.574c.008.142.023.283.023.426 0 4.411-4.037 8-9 8-.02 0-.04-.004-.059-.004C10.151 18.79 12.41 20 15 20c.961 0 1.877-.167 2.71-.467l2.265 1.697A.641.641 0 0 0 21 20.717v-3.639c.631-.9 1-1.951 1-3.078 0-2.4-1.647-4.466-4.023-5.426Z"
      fill="currentColor"
    />
  </svg>
)

export default Contact
