import React, { useState, useEffect } from "react";
import { ChevronDown } from "../../../../../assets/icons/Index";
import { motion, AnimatePresence } from "framer-motion";

import * as Styled from "./StylesSelectInput";

const SelectInput = ({ onNext, type, flowValue, entryTime }) => {
  const [value, setValue] = useState();
  const [visible, isVisible] = useState();
  const [selected, setSelected] = useState("Seleccionar");
  const [active, isActive] = useState(false);

  const reservationDuration = [
    {
      id: "1",
      type: "rduration",
      name: "12 Horas",
      value: "12"
    },
    {
      id: "2",
      type: "rduration",
      name: "24 Horas",
      value: "24"
    },
    {
      id: "3",
      type: "rduration",
      name: "Más de 24 Horas",
      value: "24+"
    }
  ];
  const entryTimeOptions = [
    {
      id: "1",
      type: "entryTime",
      name: "Día",
      value: "day"
    },
    {
      id: "2",
      type: "entryTime",
      name: "Noche",
      value: "night"
    }
  ];
  const exitTimeOptions = [
    {
      id: "1",
      type: "exitTime",
      name: "Día",
      value: "twilight"
    },
    {
      id: "2",
      type: "exitTime",
      name: "Noche",
      value: "afternoon"
    }
  ];

  const [options, setOptions] = useState();

  const handleClick = (m) => {
    setValue(m.value);
    setSelected(m.name);
  };

  const handleNext = () => {
    onNext(value);
    setSelected("Seleccionar");
    const handleFlow = () => {
      switch (type) {
        case "rduration":
          console.log(value)
          if (value === '12') {
            flowValue('12')
          } else if (value === '24') {
            flowValue('24')
          } else if (value === '24+') {
            flowValue('24+')
          }
          break;
        case "entryTime":
          flowValue("rduration");
          break;
        case "exitTime":
          flowValue("summary");
          break;
        default:
          break;
      }
    };
    handleFlow();
  };

  useEffect(() => {
    if (type === "entryTime") {
      setOptions(entryTimeOptions);
    } else if (type === "exitTime") {
      setOptions(exitTimeOptions);
    } else if (type === "rduration") {
      setOptions(reservationDuration);
    }
    const handleVisibility = () => {
      switch (type) {
        case "rduration":
          isVisible(true);
          break;
        case "entryTime":
          isVisible(true);
          setValue(entryTime);
          setSelected(entryTime === 'day' ? 'Día' : 'Noche')
          break;
        case "exitTime":
          isVisible(true);
          break;
        default:
          isVisible(false);
          break;
      }
    };
    handleVisibility();
  }, [type]);

  useEffect(() => {
    const closeDDown = (e) => {
      if (e.target.id !== "ddown") {
        if (e.target.parentElement.id !== "ddown") {
          isActive(false);
        }
      }
    };
    document.body.addEventListener("click", closeDDown);
    return () => document.body.removeEventListener("click", closeDDown);
  }, []);

  const handleCancel = () => {
    flowValue('closeModal')
  };

  return (
    <Styled.SelectInputWrapper visible={visible}>
      <Styled.SelectInputHeaderWrapper>
        <AnimatePresence mode={"popLayout"}>
          {type === "rduration" ? (
            <>
              <Styled.SelectInputLabel
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  x: -20
                }}
                animate={{
                  opacity: 1,
                  x: 0
                }}
                exit={{
                  opacity: 0,
                  x: 20
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"2"}
              >
                Tiempo de Estadia:
              </Styled.SelectInputLabel>
              <Styled.SelectInputDescription
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  y: -10
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                exit={{
                  opacity: 0,
                  y: 10
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"1.2"}
              >
                12 Horas, 24 Horas o Más de 24 Horas
              </Styled.SelectInputDescription>
            </>
          ) : type === "entryTime" ? (
            <>
              <Styled.SelectInputLabel
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  x: -20
                }}
                animate={{
                  opacity: 1,
                  x: 0
                }}
                exit={{
                  opacity: 0,
                  x: 20
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"3"}
              >
                Tiempo de entrada:
              </Styled.SelectInputLabel>
              <Styled.SelectInputDescription
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  y: -10
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                exit={{
                  opacity: 0,
                  y: 10
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"1.3"}
              >
                Selecciona el tiempo de entrada
              </Styled.SelectInputDescription>
            </>
          ) : type === "exitTime" ? (
            <>
              <Styled.SelectInputLabel
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  x: -20
                }}
                animate={{
                  opacity: 1,
                  x: 0
                }}
                exit={{
                  opacity: 0,
                  x: 20
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"4"}
              >
                Tiempo de salida:
              </Styled.SelectInputLabel>
              <Styled.SelectInputDescription
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  y: -10
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                exit={{
                  opacity: 0,
                  y: 10
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"1.4"}
              >
                Selecciona el tiempo de salida
              </Styled.SelectInputDescription>
            </>
          ) : null}
        </AnimatePresence>
      </Styled.SelectInputHeaderWrapper>
      <Styled.SelectInputField>
        <Styled.SelectInputFieldValue>{selected}</Styled.SelectInputFieldValue>
        <Styled.SelectInputFieldDDown
          active={active}
          onClick={() => isActive(!active)}
          id="ddown"
        >
          <ChevronDown id="ddown" />
        </Styled.SelectInputFieldDDown>
        {active && (
          <AnimatePresence>
            <Styled.SelectInputFieldOptionsWrapper
              active={active}
              as={motion.div}
              initial={{
                opacity: 0.5,
                scale: 0.8
              }}
              animate={{
                opacity: 1,
                scale: 1
              }}
              exit={{
                opacity: 0,
                scale: 0.8
              }}
              transition={{
                type: "spring",
                duration: 0.3
              }}
            >
              {options.map((m) => (
                <Styled.SelectInputFieldOption
                  onClick={() => handleClick(m)}
                  key={m.id}
                >
                  <p>{m.name}</p>
                </Styled.SelectInputFieldOption>
              ))}
            </Styled.SelectInputFieldOptionsWrapper>
          </AnimatePresence>
        )}
      </Styled.SelectInputField>
      <Styled.CTAWrapper>
        <Styled.CTAButton as={motion.div} whileTap={{ scale: 0.9 }} onClick={handleCancel} >
          <p>cancelar</p>
        </Styled.CTAButton>
        <Styled.CTAButton
          color={"featured"}
          as={motion.div}
          whileTap={{ scale: 0.9 }}
          onClick={handleNext}
        >
          <p>siguiente</p>
        </Styled.CTAButton>
      </Styled.CTAWrapper>
    </Styled.SelectInputWrapper>
  );
};

export default SelectInput;
