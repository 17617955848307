import React from "react";
import { motion } from "framer-motion";

import FacebookCircled from "../../assets/icons/FacebookCircled";
import LockIcon from '../../assets/icons/LockIcon'
import Instagram from "../../assets/icons/Instagram";
import LogoHorizontal from "../../assets/icons/LogoHorizontal";
import Twitter from "../../assets/icons/Twitter";
import WhatsApp from "../../assets/icons/WhatsApp";

import "./Footer.scss";

const Footer = () => {
  return (
  <motion.div 
    className="footer"
    initial={{
      opacity: 0
    }}
    whileInView={{
      opacity: 1
    }}
    transition={{
      duration: 1.5,
      ease: 'easeOut',
    }}
  >
    <div className="footer__logo">
      <LogoHorizontal />
      <p>
      Calle Vecinal a las Brumas, Canton las Lomas de San Marcelino, Santa Ana, El Salvador. <br />
      Todos los Derechos Reservados, © 2022 eljardindelosvolcanes.com
      </p>
    </div>
    <div className="footer__links">
      <a 
      href="https://www.privacypolicies.com/live/8889e16d-8a7b-45eb-a1cc-121e51f0ab61"
      aria-label="Privacy Policy"
      rel="noopener noreferrer"
      target="_blank"
      >
        <p>
          política de privacidad
        </p>
        <LockIcon />
      </a>
      <a 
      href="https://www.facebook.com/eljardindelosvolcanes"
      aria-label="Facebook"
      rel="noopener noreferrer"
      target="_blank"
      >
        <p>
          facebook
        </p>
        <FacebookCircled />
      </a>
      <a 
      href="https://twitter.com/jardinvolcanes"
      aria-label="Twitter"
      rel="noopener noreferrer"
      target="_blank"
      >
        <p>
          twitter
        </p>
        <Twitter />
      </a>
      <a 
      href="https://www.instagram.com/jardinvolcanes/"
      aria-label="Instagram"
      rel="noopener noreferrer"
      target="_blank"
      >
        <p>
          instagram
        </p>
        <Instagram />
      </a>
      <a 
      href="https://wa.link/6pqrfs"
      aria-label="WhatsApp"
      rel="noopener noreferrer"
      target="_blank"
      >
        <p>
          whatsapp
        </p>
        <WhatsApp />
      </a>
    </div>
  </motion.div>
  
  )
};

export default Footer;
