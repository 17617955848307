import * as React from "react";

const Parking = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5 3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5Zm0 2h14v14H5V5Zm6.98 2c-1.35 0-2.3.09-2.98.21V17h2v-3.59c.2.03.65.08.94.08 1.3 0 2.41-.32 3.16-1.04.58-.56.9-1.39.9-2.36s-.42-1.79-1.04-2.29c-.65-.53-1.62-.8-2.98-.8Zm.11 1.69c1.1 0 1.73.55 1.73 1.46 0 1.02-.73 1.62-1.9 1.62-.32 0-.73-.07-.92-.12V8.81c.16-.04.65-.12 1.09-.12Z"
      fill="currentColor"
    />
  </svg>
);

export default Parking;
