import { useState, useEffect } from 'react'
import { supabase } from '../../supabaseClient'
import { motion } from 'framer-motion'
import dayjs from "dayjs"
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import styled from 'styled-components'

import './Account.scss'
import './Account.css'
import { LogOut, Save, Edit, Contact, CalendarPlus, Day, Night, Cancel } from '../../assets/icons/Index'
import Avatar from '../../widgets/Avatar'
import { NavLink } from 'react-router-dom'

const CTAButton = styled(NavLink)`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background-color: var(--primary-color);
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  text-decoration: none;

  p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: clamp(0.75em, 1em, 1.1vw);
    text-align: right;
    text-transform: uppercase;
    color: var(--neutral-light);
    background: none;
  }

  svg {
    font-size: clamp(0.75em, 1em, 1.1vw);
    color: var(--neutral-light);
    background: none;
  }
`

const Account = ({ session }) => {
    dayjs.locale('es');

    const isBetween = require('dayjs/plugin/isBetween');
    dayjs.extend(isBetween);
  
    const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
    dayjs.extend(isSameOrAfter);

    const [gettingprofile, setGettingProfile] = useState(true);
    const [fbdata, setFBData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [username, setUserName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [avatar_url, setAvatarUrl] = useState(null);
    const [datavalues, setDataValues] = useState(true);
    const [user_reservations, setUserReservations] = useState();
    const [userresdata, setUserResData] = useState(false);

    // useEffect(() => {
    //     // console.log('I got mounted Type of Login')
    //     typeOfLogin()
    // }, [])
    
    useEffect(() => {
        // console.log('I got mounted FBData')
        // if (session.user.app_metadata.provider === 'facebook') {
        //     handleFBData()
        // } else {
        //     setDataValues(false);
        // }
        getProfile();
        getUserReservations();
    }, [])

    const getProfile = async () => {
        try {
            setGettingProfile(true);
            const { user } = session

            let { data, error, status } = await supabase
                .from('profiles')
                .select('email, username, phone, avatar_url')
                .eq('id', user.id)
                .single()

            if (error && status !== 406) {
                throw error
            }
            
            if (data) {
                setUserName(data.username)
                setEmail(data.email)
                setPhone(data.phone)
                if(data.avatar_url !== null || data.avatar_url === '') {
                    setAvatarUrl(data.avatar_url)
                }
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setGettingProfile(false);
            // console.log('getprofileended')
            // console.log(username)
            // console.log(email)
            // console.log(avatar_url)
            // console.log(phone)
        }
    }

    const getUserReservations = async () => {
        try {
            let { data, error, status } = await supabase
                .from('reservations')
                .select()
                .eq('user_id', session.user.id)
                .order('from_date', { ascending: true })

            if (error && status !== 406) {
                throw error
            }

            if (data) {
                setUserReservations(data);
            }
        }
        catch (error) {
            alert(error.message)
        }
        finally {
            setUserResData(true);
        }
    }
    
    useEffect(() => {
        if (gettingprofile === false && session.user.app_metadata.provider === 'facebook' && avatar_url === null) {
            getFbProfilePic();
        } else {
            setLoading(false);
        }
    }, [gettingprofile])

    const getFbProfilePic = async () => {
        try {
            setFBData(true);

            await window.FB.api(
                '/me',
                'GET',
                {"":null,"fields":"picture.width(800).height(800)","access_token":session.provider_token,"redirect":"true"},
                function(response) {
                    if(!response.error) {
                        setAvatarUrl(response.picture.data.url);
                        setValues();
                    }
                }
              );
        } 
        catch (error) {
            alert(error.message)
        }
    }

    const setValues = async () => {
        try {
            const { user } = session

            const updates = {
                id: user.id,
                avatar_url,
                updated_at: new Date(),
            }

            let { error } = await supabase.from('profiles').upsert(updates)

            if (error) {
                throw error
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false);
        }
    }

    const updateProfile = async (e) => {
        e.preventDefault()

        try {
            setLoading(true)
            const { user } = session

            const updates = {
                id: user.id,
                username,
                email,
                phone,
                updated_at: new Date(),
            }

            let { error } = await supabase.from('profiles').upsert(updates)

            if (error) {
                throw error
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false);
        }
    }

    const [penamestate, setPENameState] = useState(false);
    const [peemailstate, setPEEmailState] = useState(false);
    const [pephonestate, setPEPhoneState] = useState(false);

    return (
        <div className='account'>
            {loading ? (
                <div className='auth-loader'></div>
            ) : (
                <>
                    <motion.div className='account__detailscontainer'
                         initial={{
                            opacity: 0
                          }}
                          whileInView={{
                            opacity: 1
                          }}
                          transition={{
                            duration: 0.4
                          }}
                          viewport={{
                            once: true
                          }}
                    >
                        <div className='accdetailscontainer__header'>
                            <Avatar 
                                url={avatar_url}
                                onUpload={getProfile}
                                session={session}
                            />
                            <div className='accdetheader__userinfowrapper'>
                                <h3>
                                    {username || session.user.user_metadata.name }
                                </h3>
                                <p>
                                    Te uniste el <span style={{ textTransform: 'capitalize', background: 'none' }}>{dayjs(session.user.created_at).format(`DD - MMMM - YYYY`)}</span>
                                </p>
                                {userresdata
                                ?
                                (
                                    user_reservations.length === 0
                                    ? <p> Aun no tienes reservaciones, ¡¿Que esperas?, Reserva Ya! </p>
                                    : <p> Haz reservado {user_reservations.length} veces! </p>
                                )
                                :
                                (
                                    <div className='auth-loader'></div> 
                                )
                                }
                            </div>
                        </div>
                        <form className='accdetfields__wrapper' >
                            <div className='accdetfiewrap__row'>
                                <div className='accdetfiewraprow__title'>
                                    <p>
                                        Nombre:
                                    </p>
                                </div>
                                <div className='accdetfiewraprow__inputwrapper'>
                                    <motion.div className='accdetfiewraprow__inputedit'
                                        whileHover={{
                                            scale: 1.1
                                        }}
                                        whiletap={{
                                            scale: 0.9
                                        }}
                                    >
                                        <Edit onClick={() => setPENameState(!penamestate)} style={{ display: penamestate === true ? 'none' : 'flex' }} />
                                        <Cancel onClick={() => setPENameState(!penamestate)} style={{ display: penamestate === true ? 'flex' : 'none' }} />
                                    </motion.div>
                                    <input
                                        style={{
                                            pointerEvents: penamestate === true ? 'all' : 'none'
                                        }}
                                        className='accdetfiewraprow__input'
                                        id='name'
                                        type="text"
                                        defaultValue={ username || session.user.user_metadata.name || '' }
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='accdetfiewrap__row'>
                                <div className='accdetfiewraprow__title'>
                                    <p>
                                        Correo:
                                    </p>
                                </div>
                                <div className='accdetfiewraprow__inputwrapper'>
                                    <motion.div className='accdetfiewraprow__inputedit'
                                        whileHover={{
                                            scale: 1.1
                                        }}
                                        whiletap={{
                                            scale: 0.9
                                        }}
                                    >
                                        <Edit onClick={() => setPEEmailState(!peemailstate)} style={{ display: peemailstate === true ? 'none' : 'flex' }} />
                                        <Cancel onClick={() => setPEEmailState(!peemailstate)} style={{ display: peemailstate === true ? 'flex' : 'none' }} />
                                    </motion.div>
                                    <input
                                        style={{
                                            pointerEvents: peemailstate === true ? 'all' : 'none'
                                        }}
                                        className='accdetfiewraprow__input'
                                        id='email'
                                        type="text"
                                        defaultValue={ email || session.user.email || '' }
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='accdetfiewrap__row'>
                                <div className='accdetfiewraprow__title'>
                                    <p>
                                        Teléfono:
                                    </p>
                                </div>
                                <div className='accdetfiewraprow__inputwrapper'>
                                    <motion.div className='accdetfiewraprow__inputedit'
                                        whileHover={{
                                            scale: 1.1
                                        }}
                                        whiletap={{
                                            scale: 0.9
                                        }}
                                    >
                                        <Edit onClick={() => setPEPhoneState(!pephonestate)} style={{ display: pephonestate === true ? 'none' : 'flex' }} />
                                        <Cancel onClick={() => setPEPhoneState(!pephonestate)} style={{ display: pephonestate === true ? 'flex' : 'none' }} />
                                    </motion.div>
                                    <input
                                        style={{
                                            pointerEvents: pephonestate === true ? 'all' : 'none'
                                        }}
                                        className='accdetfiewraprow__input'
                                        id='phone'
                                        type="text"
                                        placeholder='e.g +503 7040-7668'
                                        defaultValue={ phone || '' }
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                            </div>
                        </form>
                        <motion.button className='accdetform__submit' disabled={loading} onClick={updateProfile}
                            whileHover={{
                                scale: 1.1
                            }}
                            whileTap={{
                                scale: 0.9
                            }}
                        >
                            <p>
                                Guardar Cambios
                            </p>
                            <Save />
                        </motion.button>
                    </motion.div>
                    <motion.div className='account__mcontrolcontainer'
                         initial={{
                            opacity: 0
                          }}
                          whileInView={{
                            opacity: 1
                          }}
                          transition={{
                            duration: 0.6
                          }}
                          viewport={{
                            once: true
                          }}
                    >
                        { userresdata 
                        ?
                        (
                            user_reservations.length != 0
                            ?
                            <div className='accmcontrol__reshistorywrapper'>
                                <div className='accmconreshi__header'>
                                    <h3>
                                        historial de reservaciones
                                    </h3>
                                </div>
                                <div className='accmconreshi__dateswrapper'>
                                    {
                                        user_reservations.map((user_reservations) => 
                                            <div className='accmconreshidawra__row ' key={user_reservations.id}>
                                                <div className='accmconreshidawraro__date'>
                                                    <p>
                                                        {dayjs(user_reservations.from_date).format('MMM - DD - YY')}
                                                    </p>
                                                    {user_reservations.from_time_of_day === 'day'
                                                    ? <Day />
                                                    : <Night style={{ color: '#374758' }} />
                                                    }
                                                </div>
                                                <p>
                                                    -
                                                </p>
                                                <div className='accmconreshidawraro__date'>
                                                    <p>
                                                        {dayjs(user_reservations.to_date).format('MMM - DD - YY')}
                                                    </p>
                                                    {user_reservations.to_time_of_day === 'day'
                                                    ? <Day />
                                                    : <Night style={{ color: '#374758' }} />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className='accmconcontent__ctas'>
                                    <CTAButton
                                        to='/disponibilidad'
                                    >
                                        <p>
                                            agendar
                                        </p>
                                        <CalendarPlus />
                                    </CTAButton>
                                    <CTAButton
                                        style={{
                                            backgroundColor: '#483B32'
                                        }}
                                        to='/chat'
                                    >
                                        <p>
                                            contactar
                                        </p>
                                        <Contact />
                                    </CTAButton>
                                </div>
                            </div>
                            : 
                            <div className='accmcontrol__reshistorywrapper'>
                            <div className='accmconreshi__header'>
                                <h3>
                                    historial de reservaciones
                                </h3>
                            </div>
                            <div className='accmconreshidawra__row' style={{ alignItems: 'center', justifyContent: 'center' }} >
                                    <p> Aun no tienes reservaciones :(</p>
                            </div>
                            <div className='accmconcontent__ctas'>
                                <motion.div
                                    style={{
                                        background: 'none',
                                        width: 'fit-content',
                                        height: 'fit-content'
                                    }}
                                    whileHover={{
                                        scale: 1.1
                                    }}
                                    whileTap={{
                                        scale: 0.9
                                    }}
                                >
                                    <CTAButton
                                        to='/disponibilidad'
                                    >
                                        <p>
                                            reservar
                                        </p>
                                        <CalendarPlus />
                                    </CTAButton>
                                </motion.div>
                                <motion.div
                                    style={{
                                        background: 'none',
                                        width: 'fit-content',
                                        height: 'fit-content'
                                    }}
                                    whileHover={{
                                        scale: 1.1
                                    }}
                                    whileTap={{
                                        scale: 0.9
                                    }}
                                >
                                    <CTAButton
                                        style={{
                                            backgroundColor: '#483B32'
                                        }}
                                        to='/chat'
                                    >
                                        <p>
                                            contactar
                                        </p>
                                        <Contact />
                                    </CTAButton>
                                </motion.div>
                            </div>
                            </div>
                        )
                        :
                        (
                            <div className='auth-loader'></div>
                        )
                        }
                        <motion.button type='button' className='accpan__logout' onClick={() => supabase.auth.signOut()}
                            whileHover={{
                                scale: 1.1
                            }}
                            whiletap={{
                                scale: 0.9
                            }}
                        >
                            <p>
                                Salir
                            </p>
                            <LogOut />
                        </motion.button>
                    </motion.div>
                </>
            )}
        </div>
    )
}

export default Account