import { Route, Routes, useLocation } from "react-router";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { Home, Disponibilidad, Comollegar, Blog, Chat, NotFound, BlogPost } from "./pages/Index";
import { useEffect, useState } from "react";
import { supabase } from "./supabaseClient";
import Auth from './pages/Auth/Auth'
import Account from './pages/Account/Account'
import ScrollToTop from './ScrollTopTop'
import { Helmet } from 'react-helmet'

function App() {
  const location = useLocation();
  
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  return (
      <>
      <Helmet>
        <meta
        content="Deja que la neblina te envuela; y el suave rugir del bosque te haga escuchar su voz."
        property="og:description"
        />
        <meta property="og:type" content="image/jpeg" />
        <meta property="og:url" content="https://eljardindelosvolcanes.com" />
        <meta
          property="og:image"
          content="https://eljardindelosvolcanes.com/og-image.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@jardinvolcanes" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#483B32" />
        <meta
          name="description"
          content="Lugar de campamentos, retiros y vistas impresionantes; rodeado de soberbios volcanes 🌋 y nubes majestuosas ⛅! ¡Haz tu reservacion!"
        />
        <title>El Jardin de Los Volcanes</title>
      </Helmet>
      <Navbar />
        <ScrollToTop />
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/disponibilidad" element={
            !session ?
            <Auth />
            : <Disponibilidad session={session} />
          } exact />
          <Route path="/como-llegar" element={<Comollegar />} exact/>
          <Route path="/blog" element={<Blog />} exact/>
          <Route path="/blog/:slug" element={<BlogPost />}/>
          <Route path="/cuenta" element={
            !session ?
            <Auth />
            : <Account key={session.user.id} session={session}/>
          } />
          <Route path="/chat" element={
            !session ?
            <Auth />
            : <Chat session={session} />
          } exact />
          <Route path='/*' element={<NotFound />} />
        </Routes>
      <Footer />
      </>
  );
}

export default App;
