import * as React from "react";

const CircledRight = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Zm.75 13.25a1 1 0 0 1 0-1.414l.836-.836H8a1 1 0 0 1 0-2h5.586l-.836-.836a1 1 0 0 1 1.414-1.414l2.543 2.543a1 1 0 0 1 0 1.414l-2.543 2.543a1 1 0 0 1-1.414 0Z"
      fill="currentColor"
    />
  </svg>
);

export default CircledRight;
