import * as React from "react";

const LogCabin = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11.984 2a1 1 0 0 0-.691.293l-9 9A1 1 0 0 0 3 13h1v6c0 1.093.907 2 2 2h3a1 1 0 0 0 1-1v-7h4v7a1 1 0 0 0 1 1h3c1.093 0 2-.907 2-2v-6h1a1.001 1.001 0 0 0 .707-1.707l-9-9A1 1 0 0 0 11.984 2ZM12 4.414 14.586 7H9.414L12 4.414ZM7.414 9h9.172l2 2H5.414l2-2ZM6 13h2v2H6v-2Zm10 0h2v2h-2v-2ZM6 17h2v2H6v-2Zm10 0h2v2h-2v-2Z"
      fill="currentColor"
    />
  </svg>
);

export default LogCabin;
