import * as React from "react"

const BathRoom = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5 2c-.522 0-1.055.191-1.432.568C3.191 2.945 3 3.478 3 4v9c0 2.794 1.647 5.137 4 6.613V22h10v-2.387c2.353-1.476 4-3.819 4-6.613v-1H11V4c0-.522-.191-1.055-.568-1.432C10.055 2.191 9.522 2 9 2H5Zm0 2h4v8H5V4Zm1 1v3h2V5H6Zm-.69 9h13.38c-.358 1.726-1.377 3.251-3.167 4.22l-.523.284V20H9v-1.496l-.523-.283C6.687 17.25 5.668 15.726 5.31 14Z"
      fill="currentColor"
    />
  </svg>
)

export default BathRoom
