import * as React from "react";

const BunkBed = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3 3v18h2v-2h10v2h2v-2h2v2h2V3h-2v2h-2V3h-2v4H7a1 1 0 0 0-1 1v1H5V3H3Zm14 4h2v2h-2V7ZM5 11h10v4H7a1 1 0 0 0-1 1v1H5v-6Zm12 0h2v2h-2v-2Zm0 4h2v2h-2v-2Z"
      fill="currentColor"
    />
  </svg>
);

export default BunkBed;
