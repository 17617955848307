import * as React from "react"

const DownArrow = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11 1v18H8l4 4 4-4h-3V1h-2Z" fill="currentColor" />
  </svg>
)

export default DownArrow