import * as React from "react"

const Relax = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20 18h-3v-5.17A2.83 2.83 0 0 0 14.17 10h-3.846l1.813-3.94a3.013 3.013 0 0 0-.606-3.388.952.952 0 0 0-1.345 0l-7.805 8.044h.005A1.82 1.82 0 0 0 2 11.83v7.34A2.83 2.83 0 0 0 4.83 22H20a2 2 0 1 0 0-4Z"
      fill="currentColor"
    />
  </svg>
)

export default Relax
