import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { initFacebookSdk } from "./helpers/initFacebookSDK";
import { history } from './helpers/history';
import { ToastContainer, toast } from 'react-toastify'

import App from "./App";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';


initFacebookSdk().then(() => {
// console.log(`I'm Loaded Root`)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter history={history}>
      <App />
      <ToastContainer />
    </BrowserRouter>
  </React.StrictMode>,
);
});

serviceWorkerRegistration.register({
  onUpdate: registration => {
    toast.warn('¡Nueva Versión Disponible! Para actualizar, cierra esta ventana.', {
      toastId: 'pwaUpdate',
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      onClose: () => {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    });
  }
})