import * as React from "react"

const ElectricPlant = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M4 2v2h4V2H4Zm12 0v2h4V2h-4ZM2 6a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H2Zm1 2h18v10H3V8Zm12 2v2h-2v2h2v2h2v-2h2v-2h-2v-2h-2ZM5 12v2h5v-2H5Z"
      fill="currentColor"
    />
  </svg>
)

export default ElectricPlant
