import * as React from "react";

const DiningTable = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M11 2v2.188C9.843 4.604 9 5.707 9 7c0 1.645 1.355 3 3 3s3-1.355 3-3c0-1.293-.843-2.395-2-2.813V2h-2ZM6.5 6a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM12 6c.564 0 1 .436 1 1 0 .564-.436 1-1 1-.564 0-1-.436-1-1 0-.564.436-1 1-1Zm5.5 0a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-10 3a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm9 0a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM3 12v2h2.193L4 22h2.053l.584-4h10.709l.601 4H20l-1.227-8H21v-2H3Zm4.219 2h9.525l.3 2H6.929l.29-2Z"
      fill="currentColor"
    />
  </svg>
);

export default DiningTable;
