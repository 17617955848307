import React, { useState } from "react";
import { motion } from "framer-motion";

import "./Comollegar.scss";
import { Cabin3 } from "../../assets/images/Index";
import { Help } from "../../assets/icons/Index"
import Location from "../../assets/icons/Location";
import ExternalLink from "../../assets/icons/ExternalLink";

const Comollegar = () => {
  const [map, setMap] = useState(false);

  return (
    <div className="location">
      <motion.div className="location__mapcontainer"
        initial={{
          opacity: 0
        }}
        whileInView={{
          opacity: 1
        }}
        transition={{
          duration: 0.4
        }}
        viewport={{
          once: true
        }}
      >
        <svg className='loader__container' viewBox="25 25 50 50" style={{ display: map === false ? 'absolute' : 'none' }}>
                <circle className='loader__stroke' r="20" cy="50" cx="50"></circle>
        </svg>
        <iframe onLoad={() => setMap(true)} title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5478.818211742153!2d-89.62626322740986!3d13.832462168192599!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x26bb53d2a1d09f38!2sEl%20Jardin%20de%20Los%20Volcanes!5e0!3m2!1sen!2s!4v1665566076886!5m2!1ses!2s" width="100%" height="100%" allowFullScreen="yes" loading="lazy" referrerPolicy="no-referrer-when-downgrade" style={{ border: '0' }} ></iframe>
      </motion.div>
      <div className="location__detailscontainer"
        initial={{
          opacity: 0
        }}
        whileInView={{
          opacity: 1
        }}
        transition={{
          duration: 0.6
        }}
        viewport={{
          once: true
        }}
      >
        <div className="details__headercontainer">
          <motion.div className="details__header-cover "
            initial={{
              opacity: 0,
              scale: 0.8
            }}
            whileInView={{
              opacity: 1,
              scale: 1
            }}
            transition={{
              delay: 0.6,
              duration: 0.4
            }}
            viewport={{
              once: true
            }}
          >
            <img src={Cabin3} alt="Maps Cover EJDLV" />
          </motion.div>
          <div className="details__header-text">
            <Location />
            <div>
              <h3>
                el jardín de los volcanes
              </h3>
              <p>
                Calle Vecinal a las Brumas, Canton las Lomas de San Marcelino, Santa Ana, El Salvador
              </p>
            </div>
          </div>
        </div>
        <div className="details__linkscontainer">
          <motion.a
             whileHover={{
              scale: 1.1
             }}
             whileTap={{
              scale: 0.9
             }}
             className="linkscontainer__row"
             href="https://www.google.com/maps?ll=13.833239,-89.627573&z=17&t=m&hl=en&gl=US&mapclient=embed&cid=2790916558209195832"
             aria-label="Google Maps"
             rel="noopener noreferrer"
             target="_blank"
          >
            <p>
              ver en google maps
            </p>
            <ExternalLink />
          </motion.a>
          <motion.a
             whileHover={{
              scale: 1.1
             }}
             whileTap={{
              scale: 0.9
             }}
             className="linkscontainer__row"
             href="https://www.waze.com/live-map/directions/el-jardin-de-los-volcanes?to=place.w.177209482.1771898215.13443663&from=place.ChIJzRqAy0kuY48RJtbjEPXm-sE"
             aria-label="Waze Maps"
             rel="noopener noreferrer"
             target="_blank"
          >
            <p>
              ver en waze maps
            </p>
            <ExternalLink />
          </motion.a>
          <motion.a
             whileHover={{
              scale: 1.1
             }}
             whileTap={{
              scale: 0.9
             }}
             className="linkscontainer__row"
             href="https://www.bing.com/maps?osid=becb5eda-304b-447a-9550-379d09ed7e19&cp=13.833264~-89.627958&lvl=19.7&style=a&pi=0&v=2&sV=2&form=S00027"
             aria-label="Bing Maps"
             rel="noopener noreferrer"
             target="_blank"
          >
            <p>
              ver en bing maps
            </p>
            <ExternalLink />
          </motion.a>
        </div>
        <div className="detailslocation__faqs">
          <div className="dlfaqs__header">
            <h3>
              preguntas frecuentes
            </h3>
            <Help />
          </div>
          <div className="dlfaqs__qacontainer">
            <h4>
              ¿Hay que llegar en vehículo 4x4?
            </h4>
            <p>
            No es necesario, pero es recomendable. En sedán, se tiene que manejar con cuidado y despacio.
            </p>
          </div>
          <div className="dlfaqs__qacontainer">
            <h4>
              ¿Hay hora de entrada y/o llegada?
            </h4>
            <p>
              Solo 12 Horas: Hora de Entrada: 7AM - Hora de Salida: 4PM, Las 24 Horas: Entrada: 7AM o 4PM, Salida: 7AM o 4PM.
            </p>
          </div>
          <div className="dlfaqs__qacontainer">
            <h4>
              ¿Que actividades se pueden hacer?
            </h4>
            <p>
              Campamentos, Retiros, Carneadas, Convivios; el terreno es tuyo mientras lo alquilas, tu decides que hacer.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comollegar;
