import * as React from "react"

const Sharing = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM5 8a2 2 0 0 0-2 2v11a1 1 0 0 0 2 0v-5h2v5a1 1 0 0 0 2 0v-8.83l2.293 2.295a.999.999 0 0 0 1.414 0L15 12.17V21a1 1 0 0 0 2 0v-5h2v5a1 1 0 0 0 2 0V10a2 2 0 0 0-2-2h-2c-.556 0-1.057.228-1.42.594l-.002-.002L12 12.172 8.414 8.586l-.025-.027-.004.004A1.99 1.99 0 0 0 7 8H5Z"
      fill="currentColor"
    />
  </svg>
)

export default Sharing
