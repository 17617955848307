import * as React from "react";

const SingleBed = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6 3a2 2 0 0 0-2 2v5.277A1.99 1.99 0 0 0 3 12v9h2v-2h14v2h2v-9a1.99 1.99 0 0 0-1-1.723V5a2 2 0 0 0-2-2H6Zm0 2h12v5h-2V8a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2H6V5Zm-1 7h14v5H5v-5Z"
      fill="currentColor"
    />
  </svg>
);

export default SingleBed;
