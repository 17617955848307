import * as React from "react";

const Night = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M17.64 3.002a1 1 0 0 0-.849 1.426c.165.351.209.827.209 1.484 0 1.278-.523 2.307-1.434 2.92C14.561 7.716 13.121 7 11.5 7a5.494 5.494 0 0 0-4.914 3.043C4.022 10.257 2 12.38 2 15a5 5 0 0 0 5 5h10a4 4 0 0 0 4-4 3.966 3.966 0 0 0-.996-2.616C21.778 12.467 23 10.616 23 8.49c0-2.948-2.346-5.356-5.26-5.488a.997.997 0 0 0-.1 0Zm1.255 2.295A3.458 3.458 0 0 1 21 8.49c0 1.95-1.56 3.51-3.51 3.51v.05c-.162-.02-.322-.05-.49-.05-.009 0-.016.003-.025.004a5.44 5.44 0 0 0-.364-1.47C18.147 9.537 19 7.792 19 5.913c0-.189-.097-.416-.105-.615Z"
      fill="currentColor"
    />
  </svg>
);

export default Night;
