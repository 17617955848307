import React, { useEffect, useState, useRef, useMemo } from 'react'
import { motion } from 'framer-motion'
import axios from 'axios'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import OneSignal from 'react-onesignal'

import './Chat.scss'
import './Chat.css'
import { ChatBG, Cabin3} from '../../assets/images/Index'
import { Clip, Day, Help, Info, Night, SendMessage } from '../../assets/icons/Index'
import { supabase } from '../../supabaseClient'

const Chat = ({ session }) => {
  //  ------------> START - Setup Time Context <------------

  dayjs.locale('es');
  const isBetween = require('dayjs/plugin/isBetween');
  dayjs.extend(isBetween);
  const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
  dayjs.extend(isSameOrAfter);

  const currentDay = useMemo(() => dayjs().toDate(), [])
  const currentMonthFirstDay = useMemo(() => dayjs(currentDay).startOf('month'))
  const currentMonthFirstDayOfFirstWeek = useMemo(() => dayjs(currentMonthFirstDay).startOf('week'))

  //  ------------> Setup Time Context - END <------------

  // ------------> START - Create or Fetch Room for Client & Admins <------------
  const [loading, setLoading] = useState(true);
  const [osinitialized, setOSInitialized] = useState(false);
  const oneSignalAppId = process.env.REACT_APP_ONESIGNAL_APP_ID;
  const receiverAppId = process.env.REACT_APP_OSRECEIVER_APP_ID;
  const receiverSecret = process.env.REACT_APP_OSRECEIVER_REST_API;
  const [settingroom, setSettingRoom] = useState(true);
  const [roomsdata, setRoomsData] = useState();
  const [firstcontact, setFirstContact] = useState(null);
  const [gettingmessages, setGettingMessages] = useState(null);
  const [nomessages, setNoMessages] = useState(null);
  const [messages, setMessages] = useState([]);
  const [roomid, setRoomId] = useState(null);

  useEffect(() => {
    setRoom()
  }, [session.user.id]) 

  const setRoom = async () => {
    setLoading(true);
    // console.log('setRoom Started')

    try {
      let { data, error, status } = await supabase
        .from('rooms')
        .select(`
          *,
          profiles(
            *
          )
        `)
        .match({
          profile_id: session.user.id
        })
      
      if (data.length === 0) {
        // console.log('setRoom Empty Data')
        setFirstContact(true);
        let { data, error } = await supabase
          .from('rooms')
          .insert({
            profile_id: session.user.id,
            created_at: new Date(),
          })
          .select(`
            *,
            profiles(
              *
            )
          `)
        
        if (error) {
          throw error
        }

        if (data) {
          // console.log('Insert Returned Data')
          setRoomsData(data);
        }
      }
      
      if (error && status !== 406) {
        // console.log('setRoom Error Not 406')
        throw error
      }

      if (data.length !== 0) {
        // console.log('setRoom Data?')
        setRoomsData(data);
        setFirstContact(false);
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      setSettingRoom(false)
      // console.log('setRoom Finished')
    }
  }

  // console.log(roomsdata)

  // ------------> Create or Fetch Room for Client & Admins - END <------------

  // ------------> START - Add Admins to Room if First Time Contacting <------------

  useEffect(() => {
    if (firstcontact === true) {
      addRoomAdmins()
    } else if (settingroom === false) {
      setGettingMessages(true)
    }
  }, [settingroom])

  useEffect(() => {
    if (settingroom === false) {
      initOneSignal();
    }
  }, [settingroom])

  const initOneSignal = async () => {
    try {
      const response = await OneSignal.init({
        appId: oneSignalAppId,
        notifyButton: {
          enable: true,
        }
      })
    }
    catch(error) {
      alert(error.message)
    }
    finally {
      setOSInitialized(true);
      // OneSignal.showSlidedownPrompt().then(() => {
      //   console.log('I can do stuff now')
      // })
    }
  }

  useEffect(() => {
    if (osinitialized === true) {
      const init = async () => {
        const playerId = await createNewPlayer();
        await subscribeToChatRoom(playerId);
      };

      init();
    }
  }, [osinitialized])

  const createNewPlayer = async () => {
    const { user } = session

    const response = await axios.post("https://onesignal.com/api/v1/players", {
      app_id: oneSignalAppId,
      device_type: 2,
      identifier: user.id,
      language: "es",
      timezone: -6.0,
    });

    return response.data.id
  }

  const subscribeToChatRoom = async (playerId) => {
    const response = await axios.post(`https://onesignal.com/api/v1/players/${playerId}/on_session`, {
      app_id: oneSignalAppId,
      subscribed: true,
      tags: { user_id: session.user.id },
    });

    return response.data;
  }

  const addRoomAdmins = async () => {
    // console.log('addRoomAdmins Started')
    // console.log(roomsdata.id)
    // console.log(roomsdata[0].id)
    // console.log(session.user.id)
    setRoomId(roomsdata[0].id)

    try {
      let { data, error } = await supabase
        .from('rooms')
        .select()
        .match({
          id: roomsdata[0].id
        })
        .single()
      
      if (error) {
        // console.log('addRoomAdmins Error')
        throw error
      }

      if (data) {
        // console.log('addRoomAdmins Data?')
        let { error } = await supabase
          .from('rooms')
          .insert([
            {
              id: roomsdata[0].id,
              profile_id: 'd2c01f1b-3392-4da2-bb47-598532a521a5',
              role: 'admin',
              created_at: new Date(),
            },
            {
              id: roomsdata[0].id,
              profile_id: '5bda195a-33f9-4719-988b-9d89bedd3536',
              role: 'admin',
              created_at: new Date(),
            },
          ])

        if (error) {
          // console.log('Insert Admin Error')
          throw error
        }
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      // console.log('addRoomAdmins Finished')
      setFirstContact(false)
      setGettingMessages(true)
    }
  }

  // ------------> Add Admins to Room if First Time Contacting - END <------------

  // ------------> START - Fetch All Messages for Room <------------

  useEffect(() => {
    if (gettingmessages === true && firstcontact === false) {
      getMessages()
    }
  }, [gettingmessages, firstcontact])

  const getMessages = async () => {
    // console.log('getMessages Started')
    // let RoomId = roomsdata.filter((f) => (f.profile_id === session.profile_id).map((f) => f.id))
    let ClientID = session.user.id
    // console.log(roomsdata[0].id)
    setRoomId(roomsdata[0].id)
    
    try {
      let { data, error, status } = await supabase
      .from('messages')
      .select()
      .match({
        room_id: roomsdata[0].id,
      })
      .order('created_at', {ascending: true})

      if (error && status == 406) {
        // console.log('getMessages Error 406')
        setNoMessages(true)
      }

      if (error && status !== 406) {
        // console.log('getMessages Error Not 406')
        throw error
      }

      if (data.length === 0) {
        // console.log('getMessages Data Empty')
        setNoMessages(true)
      }
      
      if (data.length !== 0) {
        // console.log('getMessages Data Defined')
        setMessages(data)
        setNoMessages(false)
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      // console.log('getMessages Finished')
      setGettingMessages(false)
      setLoading(false)
    }
  }

  // console.log(messages)

  // ------------> Fetch All Messages for Room - END <------------

  // ------------> START - Handle Message Input <------------
  const messageRef = useRef();

  const sendNotification = async (content) => {
    const response = axios.post("https://onesignal.com/api/v1/notifications", {
      app_id: receiverAppId,
      headings: { en: `Tienes un nuevo mensaje de ${roomsdata[0].profiles.username}` },
      contents: { en: content },
      tags: [{ key: "user_id", relation: "=", value: "admin" }],
      url: 'https://admin.eljardindelosvolcanes.com/chat'
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${receiverSecret}`,
      }
    }
    );

    return response.data;
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let content = messageRef.current.value;

    if (typeof content === 'string' && content.trim().length !== 0) {
      messageRef.current.value = ''
      
      try {
        let { error } = await supabase
          .from('messages')
          .insert({ profile_id: session.user.id, room_id: roomid, content: content })
        if (error) {
          throw error
        }
      }
      catch (error) {
        alert(error.message)
      }
      finally {
        await sendNotification(content);
        if (nomessages === true) {
          getMessages();
        }
        // console.log('handleMessageInput Finished')
      }
    }

  }

   // ------------> Handle Message Input - END <------------

  //  ------------> START - Subscribe to Messages Table Inserts <------------
  const messagesContainerRef = useRef(null);

  useEffect(() => {
    if (gettingmessages === false) {
      if (messagesContainerRef.current) {
        setTimeout(() => {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
        }, 500)
      }
      const subscription = supabase
      .channel('public:messages')
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'messages',
        filter: `room_id=eq.${roomsdata[0].id}`,
      }, payload => {
        // console.log('Subscribe Called')
        setMessages((current) => [...current, payload.new])
        if (messagesContainerRef.current) {
          setTimeout(() => {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
          }, 500)
        }
      })
      .subscribe() 

      return () => {
        supabase.removeChannel(subscription)
      }
    }
  }, [gettingmessages])

  //  ------------> Subscribe to Messages Table Inserts - END <------------

  //  ------------> START - Fetch Reservations Data & Check for any Active Reservations <------------
  const [checkingreservations, setCheckingReservations] = useState(true);
  const [activereservation, setActiveReservation] = useState(null);
  const [reservationdata, setReservationData] = useState();

  useEffect(() => {
    if (gettingmessages === false) {
      checkForActiveReservations()
    }
  }, [gettingmessages])

  const checkForActiveReservations = async () => {
    let user_id = session.user.id

    try {
      let { data, error, status } = await supabase
        .from('reservations')
        .select()
        .match({
          user_id: user_id
        })
        .gte('to_date', dayjs())

      if (data.length === 0) {
        setActiveReservation(false)
      }

      if (data.length !== 0) {
        setActiveReservation(true)
        setReservationData(data)
      }

      if (error && status !== 406) {
        throw error
      }
    }
    catch (error) {
      alert(error.message)
    }
    finally {
      setCheckingReservations(false);
    }
  }

  return (
    <div className='chat'>
      {loading
      ? ( <div className='chats-loader'></div> )
      :
      (
        <>
          <motion.div className='chatbox__container'
              initial={{
                opacity: 0
              }}
              whileInView={{
                opacity: 1
              }}
              transition={{
                duration: 0.4
              }}
              viewport={{
                once: true
              }}
          >
            <img src={ChatBG} alt="EJDLV Chat Background" />
            <div className='chatbox__messages-wrapper' ref={messagesContainerRef}>
              {
                nomessages
                ? ( <div></div> )
                : (
                  messages.map((messages) =>
                    <div 
                      className='messages__row-wrapper' 
                      key={messages.id} 
                      style={{
                        alignItems: messages.profile_id === session.user.id
                        ? 'flex-end'
                        : 'flex-start'
                      }}
                    >
                      <div 
                        className='messages__box'
                        style={{
                          borderRadius: messages.profile_id === session.user.id
                          ? '16px 16px 0px 16px'
                          : '16px 16px 16px 0px',
                          backgroundColor: messages.profile_id === session.user.id
                          ? '#374758'
                          : '#EBF2FA'
                        }}
                      >
                        <p
                          style={{
                            color: messages.profile_id === session.user.id
                            ? '#EBF2FA'
                            : '#374758'
                          }}
                        >
                          {messages.content}
                        </p>
                      </div>
                      <div 
                        className='messages__timestamp'
                        style={{
                          backgroundColor: messages.profile_id === session.user.id
                          ? '#374758'
                          : '#EBF2FA'
                        }}
                      >
                        <p
                          style={{
                            color: messages.profile_id === session.user.id
                            ? '#EBF2FA'
                            : '#374758'
                          }}                      
                        >
                          {dayjs(messages.created_at).format('hh.mm A')}
                        </p>
                      </div>
                    </div>
                  )
                )
              }
            </div>
            <div className='chatbox__headecontainer'>
              <div className='chaboxheadcont__wrapper'>
                <div>
                  <img src={Cabin3} alt="EJDLV Avatar" />
                </div>
                <div>
                  <h4>
                    el jardín de los volcanes
                  </h4>
                  <div>
                    <div></div>
                    <p>En Linea</p>
                  </div>
                </div>
              </div>
            </div>
            <form 
              className='chatbox__messagebar'
              onSubmit={handleFormSubmit}
            >
              {/* <div className='chabomessagebar__fileattach'>
                <Clip />
                <div></div>
              </div> */}
              <input 
                className='chabomessagebar__messageinput'
                id='message'
                name='message'
                type='text'
                placeholder='Escribe tu mensaje'
                ref={messageRef}
              />
              <motion.button 
                className='chabomessagebar__sendbutton'
                onClick={handleFormSubmit}
                whileHover={{
                  scale: 1.1
                }}
                whileTap={{
                  scale: 0.9
                }}
              >
                <SendMessage />
              </motion.button>
            </form>
          </motion.div>
          <motion.div className='chatdetails__container'
            initial={{
              opacity: 0
            }}
            whileInView={{
              opacity: 1
            }}
            transition={{
              duration: 0.6
            }}
            viewport={{
              once: true
            }}
          >
            {(checkingreservations === false && activereservation === true)
            ? (
              <>
                <div className='chatdetails__header'>
                  <h4>
                    detalles de tu reservación
                  </h4>
                </div>
                <div className='chatdetails__datawrapper'>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        nombre:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafield'>
                      <p>
                        {reservationdata.map((m) => m.name)}
                      </p>
                    </div>
                  </div>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        correo:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafield'>
                      <p>
                        {reservationdata.map((m) => m.email)}
                      </p>
                    </div>
                  </div>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        teléfono:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafield'>
                      <p>
                        {reservationdata.map((m) => m.phone)}
                      </p>
                    </div>
                  </div>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        huéspedes:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafield'>
                      <p>
                        {reservationdata.map((m) => m.guests)}
                      </p>
                    </div>
                  </div>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        desde:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafieldwrapper'>
                      <div className='chadawrarowwra__datafield'>
                        <p>
                          {reservationdata.map((m) => dayjs(m.from_date).format('DD - MMM'))}
                        </p>
                      </div>
                      <div className='chadawrarowwra__datafield'>
                        <Day  style={{ display: reservationdata.some((s) => s.from_time_of_day === 'day') ? 'flex' : 'none', color: '#DBAB03' }} />
                        <Night  style={{ display: reservationdata.some((s) => s.from_time_of_day === 'night') ? 'flex' : 'none', color: '#374758' }} />
                        <p>
                          {
                            reservationdata.some((s) => s.from_time_of_day === 'day')
                            ? 'Día'
                            : 'Noche'
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        hasta:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafieldwrapper'>
                      <div className='chadawrarowwra__datafield'>
                        <p>
                          {reservationdata.map((m) => dayjs(m.to_date).format('DD - MMM'))}
                        </p>
                      </div>
                      <div className='chadawrarowwra__datafield'>
                        <Day  style={{ display: reservationdata.some((s) => s.to_time_of_day === 'day') ? 'flex' : 'none', color: '#DBAB03' }} />
                        <Night  style={{ display: reservationdata.some((s) => s.to_time_of_day === 'night') ? 'flex' : 'none', color: '#374758' }} />
                        <p>
                          {
                            reservationdata.some((s) => s.to_time_of_day === 'day')
                            ? 'Día'
                            : 'Noche'
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        estado:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafield'>
                      <p>
                        {
                            reservationdata.some((s) => s.confirmed === true)
                            ? 'Confirmada'
                            : 'Por Confirmar'
                          }
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )
            : (
              <>
                <div className='chatdetails__header'>
                  <h4>
                    aún no tienes una reservación pendiente
                  </h4>
                </div>
                {/* <div className='chatdetails__datawrapper'>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        nombre:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafield'>
                      <p>
                        Diana Flores
                      </p>
                    </div>
                  </div>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        correo:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafield'>
                      <p>
                        diana.flores@gmail.com
                      </p>
                    </div>
                  </div>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        teléfono:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafield'>
                      <p>
                        +503 7070-7625
                      </p>
                    </div>
                  </div>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        huéspedes:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafield'>
                      <p>
                        10
                      </p>
                    </div>
                  </div>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        desde:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafieldwrapper'>
                      <div className='chadawrarowwra__datafield'>
                        <p>11 - Oct</p>
                      </div>
                      <div className='chadawrarowwra__datafield'>
                        <Day  style={{ color: '#DBAB03' }} />
                        <p>Día</p>
                      </div>
                    </div>
                  </div>
                  <div className='chadawra__rowwrapper'>
                    <div className='chadawrarowwra__fieldtitle'>
                      <h5>
                        hasta:
                      </h5>
                    </div>
                    <div className='chadawrarowwra__datafieldwrapper'>
                      <div className='chadawrarowwra__datafield'>
                        <p>12 - Oct</p>
                      </div>
                      <div className='chadawrarowwra__datafield'>
                        <Night />
                        <p>Noche</p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </>
            )
            }
            <div className='detailslocation__faqs'>
              <div className='dlfaqs__header'>
                <h3>
                  preguntas frecuentes
                </h3>
                <Help />
              </div>
              <div className='dlfaqs__qacontainer'>
                <h4>
                ¿Hay que llegar en vehículo 4x4?
                </h4>
                <p>
                No es necesario, pero es recomendable. En sedán, se tiene que manejar con cuidado.
                </p>
              </div>
              <div className='dlfaqs__qacontainer'>
                <h4>
                ¿Hay hora de entrada y/o llegada?
                </h4>
                <p>
                Hora de Entrada: 4AM - Hora de Salida: 4PM
                </p>
              </div>
              <div className='dlfaqs__qacontainer'>
                <h4>
                ¿Qué actividades se pueden hacer?
                </h4>
                <p>
                Campamentos, Retiros, Carneadas, Convivios; el terreno es tuyo mientras lo alquilas, tu decides que hacer.
                </p>
              </div>
            </div>
            <div className='chatdetails__importantbox'>
              <div className='chatdetails__importantbox-header'>
                <h4>
                  importante
                </h4>
                <Info />
              </div>
              <div className='chatdetails__importantbox-content'>
                <div className='importantbox__content-row'>
                  <p>
                  Inicio de Día:
                  </p>
                  <p>
                  4:00 AM
                  </p>
                </div>
                <div className='importantbox__content-row'>
                  <p>
                  Inicio de Noche:
                  </p>
                  <p>
                  4:00 PM
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )
      }
    </div>
  )
}

export default Chat