import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import { LogoHorizontal, LeftArrow } from '../../assets/icons/Index'
import { Error404 } from '../../assets/images/Index'
import './NotFound.scss'

const BackHomeCTA = styled(NavLink)`
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    height: fit-content;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    gap: 8px;
    margin-top: 1rem;
    margin-left: auto;
    background-color: #1796F4;
    text-decoration: none;
    transition: all 0.4s ease-out;

    &:hover {
        background-color: #165F96;
    }

    svg {
        font-size: clamp(1.25em, 1.5em, 1.5vw);
        color: #EBF2FA;
        background: none;
    }

    p {
        font-style: normal;
        font-weight: 700;
        font-size: clamp(1em, 1.25em, 1.25vw);
        text-transform: uppercase;
        color: #EBF2FA;
        background: none;
        text-decoration: none;
    }

    @media (max-width: 1440px) {
        margin-left: unset;
    }
`

const NotFound = () => {
  return (
    <div className='notfound'>
        <Helmet>
            <meta name='robots' content='noindex, nofollow' />
            <meta name='errorpage' content='true' />
            <meta name='errortype' content='404 - Not Found' />
            <meta name='prerender-status-code' content='404' />
        </Helmet>
        <div className='notfound__bg-wrapper'>
            <img src={Error404} alt="Error 404 Illustration" />
            <div className='notfound__herotext'>
                <h1>
                    Hmmmmm...
                </h1>
                <h2>
                    Parece que alguien se perdio.
                </h2>
                <BackHomeCTA
                to='/'
            >
                <LeftArrow />
                <p>
                    regresar a inicio
                </p>
            </BackHomeCTA>
            </div>
        </div>
    </div>
  )
}

export default NotFound