import * as React from "react"

const ChevronDown = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m5.707 10.707 5.586 5.586a.999.999 0 0 0 1.414 0l5.586-5.586c.63-.63.184-1.707-.707-1.707H6.414c-.89 0-1.337 1.077-.707 1.707Z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronDown
