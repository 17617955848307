import * as React from "react";

const Location = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 2a7 7 0 0 0-7 7c0 3.966 4.404 9.82 6.226 12.071a.994.994 0 0 0 1.548 0C14.596 18.82 19 12.966 19 9a7 7 0 0 0-7-7Zm0 9.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"
      fill="currentColor"
    />
  </svg>
);

export default Location;
