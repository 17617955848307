import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import MaskedInput from "react-text-mask";
import emailMask from "text-mask-addons/dist/emailMask";
import * as Styled from "./TextInputStyles";

const SelectInput = ({ onNext, type, flowValue }) => {
  const [value, setValue] = useState();
  const [visible, isVisible] = useState(false);

  const handleNext = () => {
    onNext(value);
    const handleFlow = () => {
      switch (type) {
        case "name":
          flowValue("email");
          break;
        case "email":
          flowValue("phone");
          break;
        case "phone":
          flowValue("guests");
          break;
        case "guests":
          flowValue("fromDate");
          break;
        default:
          break;
      }
    };
    handleFlow();
  };

  useEffect(() => {
    const handleVisibility = () => {
      switch (type) {
        case "name":
          isVisible(true);
          break;
        case "email":
          isVisible(true);
          break;
        case "phone":
          isVisible(true);
          break;
        case "guests":
          isVisible(true);
          break;
        default:
          isVisible(false);
          break;
      }
    };
    handleVisibility();
  }, [type]);

  const handleCancel = () => {
    flowValue('closeModal')
  };

  return (
    <Styled.SelectInputWrapper visible={visible}>
      <Styled.SelectInputHeaderWrapper>
        <AnimatePresence mode={"popLayout"}>
          {type === "name" ? (
            <>
              <Styled.SelectInputLabel
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  x: -20
                }}
                animate={{
                  opacity: 1,
                  x: 0
                }}
                exit={{
                  opacity: 0,
                  x: 20
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"1"}
              >
                a nombre de:
              </Styled.SelectInputLabel>
              <Styled.SelectInputDescription
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  y: -10
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                exit={{
                  opacity: 0,
                  y: 10
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"1.1"}
              >
                Por favor ingresa tu nombre y apellido.
              </Styled.SelectInputDescription>
            </>
          ) : type === "email" ? (
            <>
              <Styled.SelectInputLabel
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  x: -20
                }}
                animate={{
                  opacity: 1,
                  x: 0
                }}
                exit={{
                  opacity: 0,
                  x: 20
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"2"}
              >
                correo:
              </Styled.SelectInputLabel>
              <Styled.SelectInputDescription
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  y: -10
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                exit={{
                  opacity: 0,
                  y: 10
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"1.2"}
              >
                Por favor ingresa tu correo.
              </Styled.SelectInputDescription>
            </>
          ) : type === "phone" ? (
            <>
              <Styled.SelectInputLabel
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  x: -20
                }}
                animate={{
                  opacity: 1,
                  x: 0
                }}
                exit={{
                  opacity: 0,
                  x: 20
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"3"}
              >
                teléfono:
              </Styled.SelectInputLabel>
              <Styled.SelectInputDescription
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  y: -10
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                exit={{
                  opacity: 0,
                  y: 10
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"1.3"}
              >
                No olvides incluir el codigo de país
              </Styled.SelectInputDescription>
            </>
          ) : type === "guests" ? (
            <>
              <Styled.SelectInputLabel
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  x: -20
                }}
                animate={{
                  opacity: 1,
                  x: 0
                }}
                exit={{
                  opacity: 0,
                  x: 20
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"4"}
              >
                cantidad de huéspedes:
              </Styled.SelectInputLabel>
              <Styled.SelectInputDescription
                as={motion.div}
                initial={{
                  opacity: 0.5,
                  y: -10
                }}
                animate={{
                  opacity: 1,
                  y: 0
                }}
                exit={{
                  opacity: 0,
                  y: 10
                }}
                transition={{
                  type: "spring",
                  duration: 0.4
                }}
                key={"1.4"}
              >
                Ingresa la cantidad de huéspedes, puede ser un aprox.
              </Styled.SelectInputDescription>
            </>
          ) : null}
        </AnimatePresence>
      </Styled.SelectInputHeaderWrapper>
      <Styled.SelectInputField>
        <MaskedInput
          className="masked__input"
          placeholder="Ingresar Nombre"
          mask={(s) => Array.from(s).map(() => /[A-Za-z\s]/i)}
          id="name-input"
          guide={false}
          style={{
            display: type === "name" ? "flex" : "none"
          }}
          onChange={(e) => setValue(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleNext();
            }
          }}
        />
        <MaskedInput
          className="masked__input"
          placeholder="Ingresar Correo"
          mask={emailMask}
          id="email-input"
          guide={true}
          style={{
            display: type === "email" ? "flex" : "none",
            textTransform: "unset"
          }}
          onChange={(e) => setValue(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleNext();
            }
          }}
        />
        <MaskedInput
          className="masked__input"
          placeholder="Ingresar Teléfono"
          mask={[
            "+",
            /[1-9]/,
            /\d/,
            /\d/,
            " ",
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ]}
          id="phone-input"
          guide={true}
          style={{
            display: type === "phone" ? "flex" : "none",
            textTransform: "unset"
          }}
          type="tel"
          onChange={(e) => setValue(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleNext();
            }
          }}
        />
        <MaskedInput
          className="masked__input"
          placeholder="2"
          mask={[/[1-9]/, /\d/, /\d/]}
          id="phone-input"
          guide={false}
          style={{
            display: type === "guests" ? "flex" : "none",
            textTransform: "unset"
          }}
          type="tel"
          onChange={(e) => setValue(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleNext();
            }
          }}
        />
      </Styled.SelectInputField>
      <Styled.CTAWrapper>
        <Styled.CTAButton as={motion.div} whileTap={{ scale: 0.9 }} onClick={handleCancel} >
          <p>cancelar</p>
        </Styled.CTAButton>
        <Styled.CTAButton
          color={"featured"}
          as={motion.div}
          whileTap={{ scale: 0.9 }}
          onClick={handleNext}
        >
          <p>siguiente</p>
        </Styled.CTAButton>
      </Styled.CTAWrapper>
    </Styled.SelectInputWrapper>
  );
};

export default SelectInput;
