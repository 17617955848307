import React from "react";
import { motion } from "framer-motion";

import "./Disponibilidad.scss";
import Calendar from '../../components/Calendar/Calendar'

const Disponibilidad = ({ session }) => {
  return (
    <div className="availability">
      <Calendar session={session} />
    </div>
  );
};

export default Disponibilidad;
