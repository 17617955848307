import * as React from "react";

const AnimalShelter = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="m12 .955-.287.088c-3.333 1-7.34 2.197-10.674 3.197l.576 1.916 10.381-3.111c3.266.984 7.151 2.227 10.41 3.205l.575-1.914c-3.319-.996-7.346-2.289-10.694-3.293L12 .955ZM7.98 6c-1.394 0-2.69.512-3.636 1.355A1.037 1.037 0 0 0 3.574 7C2.47 7 1 9.411 1 10.332c0 .808.79 1.481 1.531 1.635l-.002.002c-.343.558-.566 1.188-.566 1.883 0 2.348 2.11 4.147 4.55 4.148h2.934c.227 0 .447-.03.666-.06a19.819 19.819 0 0 0-.084 1.482l-.002.256.121.224s.474.856 1.487 1.614C12.648 22.273 14.26 23 16.529 23c2.27 0 3.882-.727 4.895-1.484 1.013-.758 1.486-1.614 1.486-1.614l.121-.224-.002-.256c-.01-1.641-.312-2.867-.654-3.746.732-1.068.89-2.542-.049-3.705l-1.828 1.203c-.893-.637-2.19-1.174-3.969-1.174a8.88 8.88 0 0 0-2.845.459 4.249 4.249 0 0 0-.25-.486c.757-.131 1.584-.814 1.584-1.641 0-.921-1.472-3.332-2.575-3.332a1.04 1.04 0 0 0-.795.39C10.698 6.529 9.392 6 7.98 6Zm0 2c1.832 0 3.183 1.268 3.182 2.703 0 .228-.035.454-.105.686l-.182.6.459.425c.43.398.666.891.666 1.438 0 1.128-1.069 2.147-2.555 2.148H9v-1.863L10.006 13H5.957L7 14.18V16h-.484c-1.485 0-2.553-1.02-2.553-2.148 0-.545.237-1.042.666-1.44l.457-.424-.18-.597a2.376 2.376 0 0 1-.107-.688C4.799 9.267 6.149 8 7.98 8Zm8.55 6c2.15 0 3.046.863 3.48 1.43.184.24.88 1.43.972 3.7-.07.12-.177.352-.755.784-.707.528-1.845 1.086-3.698 1.086s-2.99-.558-3.697-1.086c-.593-.443-.71-.694-.773-.803.027-.649.103-1.462.17-2.002.887-.628 1.516-1.543 1.699-2.62.851-.304 1.508-.489 2.601-.489Zm-1.678 4.002L16.529 20l1.678-1.998h-3.355Z"
      fill="currentColor"
    />
  </svg>
);

export default AnimalShelter;
