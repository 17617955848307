import * as React from "react"

const ReservationForm = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5 2c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12.72l-4.429-4.447a1.001 1.001 0 0 1-.291-.705v-3.85a1 1 0 0 1 .998-1l3.857-.008a.998.998 0 0 1 .71.293l2.425 2.428H21V4c0-1.1-.9-2-2-2H5Zm3 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm4 0h4a1 1 0 0 1 0 2h-4a1 1 0 0 1 0-2Zm-4 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm0 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm8 0a1 1 0 0 0-1 1v1.018c0 .265.106.52.293.707l4.861 4.873h.002l1.221 1.222a.61.61 0 0 0 .863 0l1.58-1.58a.61.61 0 0 0 0-.863l-1.103-1.105-4.969-4.977a.995.995 0 0 0-.705-.295H16Z"
      fill="currentColor"
    />
  </svg>
)

export default ReservationForm
