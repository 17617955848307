import * as React from "react"

const PlayGround = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-2.893 3.973c-.497 0-.989.144-1.41.425l-.033.022-2.518 1.978-1.095 3.286a1 1 0 1 0 1.898.632l.905-2.714 1.832-1.44-.186.639-1.295 3.416-1.428 3.396a1 1 0 1 0 1.844.774l1.03-2.45.874.172-2.46 6.54a1 1 0 1 0 1.87.703l3.06-8.122 1.661-4.271 4.83 2.898a1 1 0 1 0 1.028-1.714l-5.008-3.006-.008-.004a2.521 2.521 0 0 0-.764-.285h-.002l-4.127-.827a2.557 2.557 0 0 0-.498-.048ZM5 18a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
      fill="currentColor"
    />
  </svg>
)

export default PlayGround
