import * as React from "react";

const FloorMattress = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.364 3.864a2.607 2.607 0 0 1 1.843-.764h3.586A2.607 2.607 0 0 1 16.4 5.707v1.586A2.607 2.607 0 0 1 13.793 9.9h-3.586A2.607 2.607 0 0 1 7.6 7.293V5.707c0-.691.275-1.354.764-1.843ZM10.207 4.9a.807.807 0 0 0-.807.807v1.586a.807.807 0 0 0 .807.807h3.586a.807.807 0 0 0 .807-.807V5.707a.807.807 0 0 0-.807-.807h-3.586Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 7.1V5.707A1.707 1.707 0 0 1 10.207 4h3.586A1.707 1.707 0 0 1 15.5 5.707V7.1h1.765c1.1 0 2.102.621 2.593 1.603l2.447 4.895A.9.9 0 0 1 22.4 14v4a2.9 2.9 0 0 1-2.9 2.9h-15A2.9 2.9 0 0 1 1.6 18v-4a.9.9 0 0 1 .095-.402l2.447-4.895A2.898 2.898 0 0 1 6.735 7.1H8.5Zm.9-1.393v1.586a.807.807 0 0 0 .807.807h3.586a.807.807 0 0 0 .807-.807V5.707a.807.807 0 0 0-.807-.807h-3.586a.807.807 0 0 0-.807.807ZM9.632 8.9H6.735c-.416 0-.796.235-.983.608L3.956 13.1h16.088l-1.796-3.592a1.098 1.098 0 0 0-.983-.608H14.37c-.183.066-.378.1-.576.1h-3.586c-.198 0-.392-.034-.575-.1Zm-6.232 6h17.2V18a1.1 1.1 0 0 1-1.1 1.1h-15A1.1 1.1 0 0 1 3.4 18v-3.1Z"
      fill="currentColor"
    />
  </svg>
);

export default FloorMattress;
