import * as React from "react";

const ExternalLink = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M19.98 2.99a1 1 0 0 0-.11.01H15a1 1 0 1 0 0 2h2.586l-9.293 9.293a1 1 0 1 0 1.414 1.414L19 6.414V9a1 1 0 1 0 2 0V4.127a1 1 0 0 0-1.02-1.137ZM5 3c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2v-6a1 1 0 1 0-2 0v6H5V5h6a1 1 0 1 0 0-2H5Z"
      fill="currentColor"
    />
  </svg>
);

export default ExternalLink;
