import styled from "styled-components";

export const CalendarWrapper = styled.div`
  display: ${(props) => (props.visible ? "flex" : "none")};
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: fit-content;
  max-width: 22.5rem;
  height: fit-content;
  align-items: flex-start;
  gap: 1rem;
  user-select: none;
  z-index: 2;
`;

export const CalendarHeaderWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  align-items: flex-start;
  justify-content: right;
  gap: 8px;
`;

export const CHeaderTitle = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content;
  height: fit-content;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 0.75em;
    color: var(--neutral-dark_2);
    text-transform: uppercase;
    letter-spacing: 0.04em;
  }
`;

export const CHeaderDateWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
`;

export const CHeaderDate = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  color: var(--neutral-dark_2);
  text-transform: capitalize;
`;

export const CHeaderDateSubTitle = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  color: var(--neutral-dark_2);
  text-transform: capitalize;
`;

export const CHeaderNavWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content;
  height: fit-content;
  gap: 8px;
`;

export const CHeaderNavButton = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  width: fit-content;
  height: fit-content;
  cursor: pointer;

  svg {
    font-size: 1.5em;
    color: var(--neutral-dark_2);
    background: none;
  }
`;

export const WeekDaysWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
`;

export const WeekDayCell = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: clamp(36px, 42px, 10vw);
  height: clamp(36px, 42px, 10vw);
  align-items: center;
  justify-content: center;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: clamp(0.75em, 1em, 1.25vw);
    text-transform: uppercase;
    color: var(--neutral-dark_2);
  }
`;

export const DaysWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const DaysCell = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: clamp(36px, 42px, 10vw);
  height: clamp(36px, 42px, 10vw);
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: ${(props) => (props.selected ? "#7d83ff" : "unset")};
  cursor: pointer;
  transition: all 0.4s ease-out;

  @media (hover: hover) {
    :hover {
      background-color: #b4bdc8;
    }
  }

  p {
    font-style: normal;
    font-weight: ${(props) => (props.selected ? "700" : "500")};
    font-size: clamp(0.75em, 1em, 1.25vw);
    color: ${(props) =>
      props.current === true && props.selected === true
        ? "var(--neutral-light)"
        : props.current
        ? "#3A9659"
        : props.selected
        ? "var(--neutral-light)"
        : "var(neutral-dark_2)"};
  }

  svg {
    display: flex;
    position: absolute;
    font-size: clamp(0.45em, 0.75em, 0.625vw);
    color: var(--neutral-light);
    background: none;
    bottom: 6px;
  }
`;

export const CTAWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;
  justify-content: flex-end;
  gap: 1rem;
`;

export const CTAButton = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 6px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.color === "featured" ? "#374758" : "#B7CEE9"};
  border-radius: 6px;
  cursor: pointer;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 0.75em;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: ${(props) =>
      props.color === "featured" ? "var(--neutral-light_2)" : "#566574"};
  }
`;
