import * as React from "react";

const Bedroom = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M5 4a2 2 0 0 0-2 2v4.277A1.99 1.99 0 0 0 2 12v9h2v-2h16v2h2v-9a1.99 1.99 0 0 0-1-1.723V6a2 2 0 0 0-2-2H5Zm0 2h14v4h-1v-.5a2.5 2.5 0 1 0-5 0v.5h-2v-.5a2.5 2.5 0 1 0-5 0v.5H5V6Zm-1 6h16v5H4v-5Z"
      fill="currentColor"
    />
  </svg>
);

export default Bedroom;
