import * as React from "react"

const LogOut = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm6.366 12.966A.802.802 0 0 1 17 14.4V13h-7v-2h7V9.6a.8.8 0 0 1 1.366-.566l2.4 2.4a.799.799 0 0 1 0 1.131l-2.4 2.401Z"
      fill="currentColor"
    />
  </svg>
)

export default LogOut
