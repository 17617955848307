import * as React from "react"

const CircledLeft = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2Zm-.75 13.25a1 1 0 0 0 0-1.414L10.414 13H16a1 1 0 0 0 0-2h-5.586l.836-.836A1 1 0 0 0 9.836 8.75l-2.543 2.543a1 1 0 0 0 0 1.414l2.543 2.543a1 1 0 0 0 1.414 0Z"
      fill="currentColor"
    />
  </svg>
)

export default CircledLeft
