import * as React from "react"

const Tent = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10.441 2.008a.996.996 0 0 0-.8 1.463l1.228 2.33L9.18 9.01l2.82.94 2.821-.94-1.69-3.21 1.22-2.33c.26-.49.07-1.091-.41-1.351-.49-.25-1.1-.07-1.36.42L12 3.65l-.59-1.12a1.003 1.003 0 0 0-.969-.522Zm-2.2 8.793-5.122 9.728a1 1 0 0 0 .022.99c.19.3.509.48.859.48h4.342c.4 0 .76-.237.918-.605l2.15-5.013c.22-.52.96-.52 1.18 0l2.15 5.013a.998.998 0 0 0 .918.606H20c.35 0 .67-.18.86-.48a1 1 0 0 0 .02-.99l-5.12-9.73-3.44 1.15c-.11.03-.21.05-.32.05-.11 0-.21-.02-.32-.05L8.24 10.8Z"
      fill="currentColor"
    />
  </svg>
)

export default Tent
